import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IdeateHelper } from '../core/providers/ideate';

@Injectable()
export class Account implements CanActivate {

	public info: any = {};

	constructor(
		private location: Location,
		private router: Router,
		public helper: IdeateHelper
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const allowedAccountType = this.helper.getObjVal(route.data, ['validations', 'account', 'type'], []);
		return new Promise((resolve, reject) => {
			this.requiredLogIn().then(() => {
				if (allowedAccountType.indexOf(this.info.type) >= 0) {
					resolve(true);
				} else {
					this.logOut();
					this.router.navigate(['login']);
					reject(false);
				}
			})
				.catch((invalid) => {
					this.logOut();
					this.router.navigate(['login']);
					reject(false);
				});
		});
	}

	requiredLogIn(): Promise<Boolean> {
		return new Promise((resolve, reject) => {
			if (this.helper.getObjVal(this.info, ['id']) === '') {
				this.isAlreadyLoggedIn().then(() => {
					/* const currPath = this.location.path();
					this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
						this.router.navigateByUrl(currPath);
					}); */
					resolve(this.info);
				})
					.catch((invalid) => {
						this.logOut();
						this.router.navigate(['login']);
						reject(false);
					});
			} else {
				resolve(this.info);
			}
		});
	}

	isAlreadyLoggedIn() {
		return new Promise((resolve, reject) => {
			let info: any = localStorage.getItem('currentUser');
			info = (this.helper.isValidJSON(info)) ? JSON.parse(info) : {};
			if (!this.helper.isEmptyObj(info)) {
				this.verifyAuthToken(info.id, info.auth_token)
					.then((resVerification) => {
						if (resVerification) {
							// this.logIn(info);
							resolve(this.info);
						} else {
							reject(false);
						}
					})
					.catch((ex) => {
						reject(false);
					});
			} else {
				reject(false);
			}
		});
	}

	logIn(user: any) {
		const settings = user.site_settings;
		delete user['site_settings'];
		this.info = user;
		localStorage.setItem('siteSettings', JSON.stringify(settings));
		localStorage.setItem('currentUser', JSON.stringify(this.info));
	}

	logOut() {
		const reqParams: any = {};
		reqParams.user_id = this.info.id;
		reqParams.auth_token = this.info.auth_token;
		this.helper.makeAPIRequest('account/logout', reqParams);
		localStorage.removeItem('siteSettings');
		localStorage.removeItem('currentUser');
		this.info = {};
		this.router.navigate(['login']);
	}

	verifyAuthToken(user_id: string, auth_token: string) {
		return new Promise((resolve, reject) => {
			const reqParams: any = {};
			reqParams.user_id = user_id;
			reqParams.auth_token = auth_token;
			this.helper.makeAPIRequest('account/validate_auth_token', reqParams).then((response) => {
				if (response.success === 1) {
					this.logIn(response.data.userdata);
					resolve(response);
				}
				reject(false);
			}).catch((httpError) => {
				reject(false);
			});
		});
	}
}