import { Component, ViewChild, OnInit, OnDestroy, ElementRef, Output, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IdeateHelper } from '../core/providers/ideate';
import { Account } from '../providers/account';
import { App } from '../providers/app';
import { EventEmitter } from 'events';
import { FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit, OnDestroy {

  public ticketsView = 'calendar';

  public filterCalendarKeyword = '';
  public filterCalendarProvider: any = {everkleen: true, wynnsbins: true};
  public filterCalendarInputsChangedSub: Subject<string> = new Subject<string>();

  public filterListKeyword = '';
  public filterListStartDate: Date = new Date();
  public filterListEndDate: Date = new Date();
  public filterListShowUnscheduled: Boolean = true;

  @ViewChild('sidebarCalendar') public sidebarCalendar: FullCalendarComponent;
  public sidebarCalendarPlugins = [dayGridPlugin];
  public sidebarCalendarAPI;

  @ViewChild('ticketsCalendarWrapper') public ticketsCalendarWrapper: ElementRef;
  @ViewChild('btnTicketsCalendarPrevView') public btnTicketsCalendarPrevView: ElementRef;
  @ViewChild('btnTicketsCalendarNextView') public btnTicketsCalendarNextView: ElementRef;
  @ViewChild('unscheduledTicketsListWrapper') public unscheduledTicketsListWrapper: ElementRef;
  @ViewChild('ticketsCalendar') public ticketsCalendar: FullCalendarComponent;
  public ticketsCalendarAPI;
  public ticketsCalendarRendered = false;
  public ticketsCalendarExpanded = false;
  public ticketsCalendarScrollbarConfig: PerfectScrollbarConfigInterface = {wheelSpeed: 0.01, handlers: ['drag-thumb']};
  public ticketsCalendarScrollingTimeOut;
  public ticketsCalendarPlugins = [dayGridPlugin, interactionPlugin];
  public ticketsCalendarEvents = [];
  public ticketsCalendarSyncInterval;
  public ticketsEditable = true;
  public ticketsLoading = false;
  public ticketsUpdating = false;
  public ticketsCalendarWrapperScrolled = 0;
  public ticketsCalendarWrapperMaxHeight = 300;
  public crewRowsTasksListsMaxHeight = 65;
  public crewsStatusBarMaxHeight = 300;

  public crews = [];
  public ticketsUnscheduled = [];

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('datatable') dtElementRef: ElementRef;
  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public dtColsCount = 0;
  public dtRows: any[];
  public selectedRecordID = 0;

  @HostListener('document:keydown', ['$event']) onMouseEnter($event: KeyboardEvent) {
    if (this.router.url === '/dashboard') {
      if ($event.which === 37 || $event.which === 38) {
        this.evChangeTicketsCalendarView('prev');
      } else if ($event.which === 39 || $event.which === 40) {
        this.evChangeTicketsCalendarView('next');
      }
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public app: App,
    public helper: IdeateHelper,
    private account: Account
  ) {
    this.app.reloadTickets.subscribe(
      (data: any) => {
        this.loadTickets(true);
      });
  }

  ngOnInit() {
    this.dtColsCount = this.dtElementRef.nativeElement.children[0].children[0].children.length;

    setTimeout(() => {
      this.sidebarCalendarAPI = this.sidebarCalendar.getApi();
      this.ticketsCalendarAPI = this.ticketsCalendar.getApi();
      this.filterListStartDate = new Date(this.ticketsCalendarAPI.view.currentStart);
      this.filterListEndDate = new Date(this.ticketsCalendarAPI.view.currentEnd);
      this.filterListEndDate.setDate(this.filterListEndDate.getDate() - 1);

      this.loadTickets(true);
      this.ticketsCalendarSyncInterval = setInterval(() => {
        this.loadTickets(false);
      }, 30000);

      this.route.paramMap.subscribe((params: ParamMap) => {
        this.initDataTable();
      });
    }, 100);

    this.filterCalendarInputsChangedSub
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(model => {
        this.loadTickets(true);
    });

    const externalEventsHandler = new Draggable(this.unscheduledTicketsListWrapper.nativeElement, {
      itemSelector: 'li.unscheduled_ticket a'
    });
  }

  scrollToCurrentWeek() {
    if (!this.ticketsCalendarExpanded) {
      const hasCurrentWeek = document.querySelectorAll('.tickets_calendar_wrapper full-calendar .fc-view > table tbody.fc-body .fc-widget-content .fc-row .fc-day.fc-today').length;
      if (hasCurrentWeek) {
        const calendarWeekRows = document.querySelectorAll('.tickets_calendar_wrapper full-calendar .fc-view > table tbody.fc-body .fc-widget-content .fc-row');
        let weekRowToGo = 0;
        for (let i = 0; i < calendarWeekRows.length; i++) {
          weekRowToGo++;
          if (calendarWeekRows[i].querySelector('.fc-day.fc-today')) {
            break;
          }
        }
        while (weekRowToGo > 0 && weekRowToGo >= calendarWeekRows.length) {
          weekRowToGo--;
        }
        if (weekRowToGo > 0) {
          for (let i = 1; i < weekRowToGo; i++) {
            setTimeout(() => {
              this.evChangeTicketsCalendarView('next');
            }, 100 * i);
          }
        }
      }
    }
  }

  resizeTicketsCalendar() {
    const windowHeight = window.innerHeight;
    const headerHeight = document.querySelector('pg-header .header').clientHeight;
    const calendarSectionHeadingHeight = document.querySelector('h5.calendar_section_heading').clientHeight + 8; // 8px Margin
    const calendarHeaderHeight = document.querySelector('.tickets_calendar_wrapper full-calendar .fc-view > table thead.fc-head').clientHeight;
    const calendarWeekRowsCount = document.querySelectorAll('.tickets_calendar_wrapper full-calendar .fc-view > table tbody.fc-body .fc-widget-content .fc-row').length;
    const miscSpacing = 16;
    const contentAreaPadding = 32;
    const contentArea = windowHeight - headerHeight - contentAreaPadding - calendarSectionHeadingHeight - calendarHeaderHeight - miscSpacing;

    this.ticketsCalendarWrapperMaxHeight = +(contentArea);
    let calendarWeekRowHeight = 150;
    if (!this.ticketsCalendarExpanded) {
      this.ticketsCalendarWrapperMaxHeight = +(this.ticketsCalendarWrapperMaxHeight / 2);
      calendarWeekRowHeight = +((this.ticketsCalendarWrapperMaxHeight / 2) - 1);
    } else {
      calendarWeekRowHeight = +((this.ticketsCalendarWrapperMaxHeight / calendarWeekRowsCount) - 1);
    }
    document.querySelector('#custom_style').innerHTML = '<style>.tickets_calendar_wrapper full-calendar .fc-view > table tbody.fc-body .fc-widget-content .fc-row{height:' + calendarWeekRowHeight + 'px!important; min-height:' + calendarWeekRowHeight + 'px!important;}</style>';
    this.ticketsCalendarAPI.updateSize();

    this.crewsStatusBarMaxHeight = +(contentArea / 2);
    // const crewsCount = this.crews.length;
    const crewsCount = document.querySelectorAll('ul.crews_list > li').length;
    const crewRows = Math.ceil(crewsCount / 7);
    let crewRowsMaxHeight = +(this.crewsStatusBarMaxHeight / crewRows);
    if (this.ticketsCalendarExpanded) {
      crewRowsMaxHeight = this.crewsStatusBarMaxHeight;
    }
    this.crewRowsTasksListsMaxHeight = crewRowsMaxHeight - 4 - 2 - 28; // Margin(4px) - Border(2px) - Heading(28)
  }

  evTicketsCalendarEventRender($event) {
    $event.el.querySelectorAll('.fc-title')[0].innerHTML = $event.event.title;
  }

  evTicketsCalendarDateClicked($event) {
    this.router.navigate(['/dashboard', {outlets: {page: ['tickets', 'create', $event.dateStr]}}]);
  }

  evTicketsCalendarTicketClicked($event) {
    const ticketID = $event.event.extendedProps.id;
    if (ticketID) {
      this.router.navigate(['/dashboard', {outlets: {page: ['tickets', 'info', ticketID]}}]);
    }
  }

  evTicketsCalendarScrolled($event) {
    this.ticketsCalendarWrapperScrolled = this.ticketsCalendarWrapper.nativeElement.scrollTop;
  }

  evTicketsCalendarWheeled($event) {
    if (this.ticketsCalendarRendered && !this.ticketsLoading) {
      const timeOut = 50;
      if ($event.deltaY < -30) {
        if (this.ticketsCalendarScrollingTimeOut) {
          clearTimeout(this.ticketsCalendarScrollingTimeOut);
        }
        this.ticketsCalendarScrollingTimeOut = setTimeout(() => {
          this.evChangeTicketsCalendarView('prev');
        }, timeOut);
      } else if ($event.deltaY > 30) {
        if (this.ticketsCalendarScrollingTimeOut) {
          clearTimeout(this.ticketsCalendarScrollingTimeOut);
        }
        this.ticketsCalendarScrollingTimeOut = setTimeout(() => {
          this.evChangeTicketsCalendarView('next');
        }, timeOut);
      }
    }
  }

  evTicketsCalendarTicketResizeStart($event) {
    this.ticketsUpdating = true;
  }

  evTicketsCalendarTicketResized($event) {
    this.ticketsEditable = false;
    const ticketID = +$event.event.extendedProps.id;
    const evStartDate = $event.event.start;
    const evEndDate = $event.event.end;
    evEndDate.setDate(evEndDate.getDate() - 1);
    this.reschedule(ticketID, evStartDate, evEndDate);
  }

  evTicketsCalendarTicketDragStart($event) {
    this.ticketsUpdating = true;
  }

  evTicketsCalendarTicketDropped($event) {
    const ticketID = +$event.event.extendedProps.id;
    const ticketsCalendarWrapperOffset = this.ticketsCalendarWrapper.nativeElement.getClientRects()[0];
    if($event.jsEvent.type === 'mouseup'){ // mouse click event handle
      var coordinate = $event.jsEvent;
    }else if ($event.jsEvent.type === 'touchend'){ // touched event handle
      var coordinate = $event.jsEvent.changedTouches[0]; // store touch pointer location
    }
    if (
      coordinate.clientX >= ticketsCalendarWrapperOffset.left &&
      coordinate.clientY >= ticketsCalendarWrapperOffset.top &&
      coordinate.clientX <= ticketsCalendarWrapperOffset.right &&
      coordinate.clientY <= ticketsCalendarWrapperOffset.bottom
    ) {
      this.ticketsEditable = false;
      const evStartDate = $event.event.start;
      const evEndDate = $event.event.end;
      evEndDate.setDate(evEndDate.getDate() - 1);
      this.reschedule(ticketID, evStartDate, evEndDate);
    } else {
      this.ticketsUpdating = false;
      $event.revert();
    }
  }

  evTicketsCalendarExternalTicketDropped($event) {
    const ticketDataJSON = $event.draggedEl.getAttribute('data-ticket');
    const ticketsCalendarWrapperOffset = this.ticketsCalendarWrapper.nativeElement.getClientRects()[0];
    if($event.jsEvent.type === 'mouseup'){ // mouse click event handle
      var coordinate = $event.jsEvent;
    }else if ($event.jsEvent.type === 'touchend'){ // touched event handle
      var coordinate = $event.jsEvent.changedTouches[0]; // store touch pointer location
    }
    if (
      ticketDataJSON &&
      coordinate.clientX >= ticketsCalendarWrapperOffset.left &&
      coordinate.clientY >= ticketsCalendarWrapperOffset.top &&
      coordinate.clientX <= ticketsCalendarWrapperOffset.right &&
      coordinate.clientY <= ticketsCalendarWrapperOffset.bottom
      ) {
      const ticket = JSON.parse(ticketDataJSON);
      if (ticket) {
        this.ticketsEditable = false;
        const ticketID = +ticket.extendedProps.id;
        const evStartDate = $event.date;
        const evEndDate = $event.date;
        this.reschedule(ticketID, evStartDate, evEndDate);
      }
    }
  }

  evTicketsCalendarTicketDragStopped($event) {
    let addCrewID = 0;
    const ticketID = +$event.event.extendedProps.id;
    let ticketObj = false;
    this.ticketsCalendarEvents.forEach(calendarEvent => {
      if (ticketID === +calendarEvent.extendedProps.id) {
        ticketObj = calendarEvent;
      }
    });
    const crewsList = document.querySelectorAll('ul.crews_list > li');
    crewsList.forEach(crewsListItem => {
      const offset = crewsListItem.getClientRects()[0];
      if($event.jsEvent.type === 'mouseup'){ // mouse click event handle
        var coordinate = $event.jsEvent;
      }else if ($event.jsEvent.type === 'touchend'){ // touched event handle
        var coordinate = $event.jsEvent.changedTouches[0]; // store touch pointer location
      }
      if (
        coordinate.clientX >= offset.left &&
        coordinate.clientY >= offset.top &&
        coordinate.clientX <= offset.right &&
        coordinate.clientY <= offset.bottom
      ) {
        addCrewID = +crewsListItem.getAttribute('data-crew_id');
      }
    });
    if (addCrewID > 0) {
      const crewIdx = this.helper.getArrayIndex(this.crews, 'id', addCrewID.toString());
      if (crewIdx !== false && ticketObj) {
        let crewTicketExist = false;
        this.crews[crewIdx].tickets.forEach(crewTicket => {
          if (ticketID === +crewTicket.extendedProps.id) {
            crewTicketExist = true;
          }
        });
        if (!crewTicketExist) {
          this.crews[crewIdx].tickets.push(ticketObj);
        }
      }
      this.updateCrew(ticketID, addCrewID, 0);
    } else {
      const unscheduledTicketsListWrapperOffset = this.unscheduledTicketsListWrapper.nativeElement.getClientRects()[0];
      if($event.jsEvent.type === 'mouseup'){ // mouse click event handle
        var coordinate = $event.jsEvent;
      }else if ($event.jsEvent.type === 'touchend'){ // touched event handle
        var coordinate = $event.jsEvent.changedTouches[0]; // store touch pointer location
      }
      if (
        coordinate.clientX >= unscheduledTicketsListWrapperOffset.left &&
        coordinate.clientY >= unscheduledTicketsListWrapperOffset.top &&
        coordinate.clientX <= unscheduledTicketsListWrapperOffset.right &&
        coordinate.clientY <= unscheduledTicketsListWrapperOffset.bottom
      ) {
        this.ticketsEditable = false;
        const evStartDate = '';
        const evEndDate = '';
        this.ticketsUnscheduled.push(ticketObj);
        this.reschedule(ticketID, evStartDate, evEndDate);
      } else {
        this.ticketsUpdating = false;
      }
    }
  }

  evCrewsTicketDropped($event, addCrewID) {
    const ticketID = +$event.el.getAttribute('data-ticket_id');
    const removeCrewID = +$event.source.getAttribute('data-crew_id');
    addCrewID = +addCrewID;
    if (ticketID && addCrewID) {
      this.updateCrew(ticketID, addCrewID, removeCrewID);
    }
  }

  evChangeTicketsCalendarView(direction: string) {
    const calendarHeaderHeight = document.querySelector('.tickets_calendar_wrapper full-calendar .fc-view > table thead.fc-head').clientHeight;
    const calendarWeekRowHeight = document.querySelector('.tickets_calendar_wrapper full-calendar .fc-view > table tbody.fc-body .fc-widget-content .fc-row').clientHeight;
    if (direction === 'prev') {
      if (this.ticketsCalendarWrapper.nativeElement.scrollTop > calendarHeaderHeight) {
        let scrollTop = Math.ceil(this.ticketsCalendarWrapper.nativeElement.scrollTop / calendarWeekRowHeight) - 1;
        scrollTop *= calendarWeekRowHeight;
        scrollTop += calendarHeaderHeight;
        const offsetStart = this.ticketsCalendarWrapper.nativeElement.scrollTop + calendarHeaderHeight;
        const offsetEnd = this.ticketsCalendarWrapper.nativeElement.scrollTop + calendarHeaderHeight;
        if (offsetStart <= scrollTop || offsetEnd >= scrollTop) {
          scrollTop -= calendarWeekRowHeight;
        }
        this.ticketsCalendarWrapper.nativeElement.scrollTop = scrollTop;
      } else {
        this.ticketsCalendarAPI.prev();
      }
    } else if (direction === 'next') {
      if ((this.ticketsCalendarWrapper.nativeElement.scrollTop + this.ticketsCalendarWrapper.nativeElement.clientHeight) < this.ticketsCalendarWrapper.nativeElement.scrollHeight) {
        this.ticketsCalendarWrapper.nativeElement.scrollTop++;
        let scrollTop = Math.ceil(this.ticketsCalendarWrapper.nativeElement.scrollTop / calendarWeekRowHeight);
        scrollTop *= calendarWeekRowHeight;
        scrollTop += calendarHeaderHeight;
        this.ticketsCalendarWrapper.nativeElement.scrollTop = scrollTop;
      } else {
        this.ticketsCalendarAPI.next();
      }
    }
  }

  evFilterCalendarInputsChanged(query: any) {
    this.filterCalendarInputsChangedSub.next(query);
  }

  evChangeTicketsView(view: string) {
    this.ticketsView = view;
    if (view === 'calendar') {
      this.loadTickets(false);
    }
  }

  transformTicketToCalendarEvent(ticket) {
    const event: any = {title: '', start: '', end: '', backgroundColor: '#ccc', borderColor: '#000000', extendedProps: {id: 0, status: ''}};
    const ticketStatus = this.app.ticketStatuses.filter((item) => (item.value === ticket.ticket_status));
    if (ticket.ticket_services && ticket.ticket_services.length > 0) {
      ticket.ticket_services.forEach((service: any) => {
        event.title += '<img class="event_title_service_icon" src="' + service.icon + '">';
      });
    }
    event.title += ticket.customer_name;
    const timezoneOffsetMS = new Date().getTimezoneOffset() * 60 * 1000;
    if (ticket.schedule_date_from !== '') {
      event.start = new Date(ticket.schedule_date_from);
      event.start = new Date(event.start.getTime() + timezoneOffsetMS);
    }
    if (ticket.schedule_date_to !== '') {
      event.end = new Date(ticket.schedule_date_to);
      event.end = new Date(event.end.getTime() + timezoneOffsetMS);
      event.end.setDate(event.end.getDate() + 1);
    }
    event.extendedProps.id = +ticket.ticket_id;
    event.extendedProps.status = ticket.ticket_status;
    if (ticket.ticket_status === 'completed') {
      event.editable = false;
    }
    if (ticketStatus && ticketStatus.length === 1) {
      event.backgroundColor = ticketStatus[0].color_030;
      event.borderColor = ticketStatus[0].color_100;
    }
    return event;
  }

  loadTickets(moveToTop) {
    if (this.ticketsCalendarAPI && !this.ticketsLoading && !this.ticketsUpdating) {
      if (moveToTop) {
        this.ticketsCalendarWrapper.nativeElement.scrollTop = 0;
      }
      this.ticketsLoading = true;
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.filter_keyword = this.filterCalendarKeyword;
      reqParams.filter_provider = [];
      for (const provider in this.filterCalendarProvider) {
        if (this.filterCalendarProvider[provider]) {
          reqParams.filter_provider.push(provider);
        }
      }
      reqParams.filter_provider = reqParams.filter_provider.join(',');
      reqParams.filter_start_date = new Date(this.ticketsCalendarAPI.view.activeStart);
      reqParams.filter_start_date = this.helper.dFormatToDB(reqParams.filter_start_date);
      reqParams.filter_end_date = new Date(this.ticketsCalendarAPI.view.activeEnd);
      reqParams.filter_end_date.setDate(reqParams.filter_end_date.getDate() - 1);
      reqParams.filter_end_date = this.helper.dFormatToDB(reqParams.filter_end_date);
      this.helper.makeAPIRequest('tickets/dashboard_data', reqParams).then((response: any) => {
        if (moveToTop) {
          this.ticketsCalendarWrapper.nativeElement.scrollTop = 0;
        }
        this.crews = [];
        this.ticketsUnscheduled = [];
        this.ticketsCalendarEvents = [];
        this.ticketsLoading = false;
        this.ticketsEditable = true;
        setTimeout(() => {
          this.ticketsCalendarRendered = true;
        }, 0);
        if (response.success === 1) {
          response.data.calendarTickets.forEach((record: any) => {
            const event = this.transformTicketToCalendarEvent(record);
            this.ticketsCalendarEvents.push(event);
          });
          response.data.crews.forEach((record: any) => {
            const crew = record;
            for (let i = 0; i < crew.tickets.length; i++) {
              crew.tickets[i] = this.transformTicketToCalendarEvent(crew.tickets[i]);
            }
            this.crews.push(crew);
          });
          response.data.unscheduledTickets.forEach((record: any) => {
            const event = this.transformTicketToCalendarEvent(record);
            this.ticketsUnscheduled.push(event);
          });
          setTimeout(() => {
            this.resizeTicketsCalendar();
            if (moveToTop) {
              setTimeout(() => {
                this.scrollToCurrentWeek();
              });
            }
          });
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultErrorMsg, this.helper.config.defaultNoResultErrorMsg);
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.ticketsLoading = false;
        this.ticketsEditable = true;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  updateCrew(ticketID: number, addCrewID: number, removeCrewID: number) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = ticketID;
    reqParams.add_crew_id = addCrewID;
    reqParams.remove_crew_id = removeCrewID;
    this.helper.makeAPIRequest('tickets/update_crew', reqParams).then((response) => {
      this.ticketsUpdating = false;
      if (response.success === 1) {
        this.helper.showNotification('success', 'Crew updated successfully!');
        this.loadTickets(false);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else if (response.errorCode === 4) {
          this.helper.showNotification('danger', 'Ticket already assigned to this crew.', this.helper.config.defaultErrorTitle);
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  reschedule(ticketID: number, scheduleDateFrom: Date|string, scheduleDateTo: Date|string) {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = ticketID;
    reqParams.schedule_date_from = this.helper.dFormatToDB(scheduleDateFrom);
    reqParams.schedule_date_to = this.helper.dFormatToDB(scheduleDateTo);
    this.helper.makeAPIRequest('tickets/reschedule', reqParams).then((response) => {
      this.ticketsUpdating = false;
      if (response.success === 1) {
        this.helper.showNotification('success', 'Ticket rescheduled successfully!');
        this.loadTickets(false);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.ticketsUpdating = false;
      this.ticketsEditable = true;
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  initDataTable() {
    setTimeout(() => {
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.loadDatable();
        });
      } else {
        this.loadDatable();
      }
    }, 0);
  }

  loadDatable() {
    const that = this;
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.filter_keyword = this.filterListKeyword;
    reqParams.filter_start_date = this.helper.dFormatToDB(this.filterListStartDate);
    reqParams.filter_end_date = this.helper.dFormatToDB(this.filterListEndDate);
    reqParams.filter_show_unscheduled = +this.filterListShowUnscheduled;
    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthMenu: [[10, 50, 100, 200, 500, 1000], [10, 50, 100, 200, 500, 1000]],
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: true,
      dom: 'l"("i")"rtp',
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters = Object.assign(dataTablesParameters, reqParams);
        this.helper.makeAPIRequest('tickets/list', dataTablesParameters).then((response) => {
          if (response.success === 1) {
            that.dtRows = response.data.datatable.rows;
            callback({
              recordsTotal: response.data.datatable.recordsTotal,
              recordsFiltered: response.data.datatable.recordsFiltered,
              data: []
            });
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    };
    setTimeout(() => { this.dtTrigger.next(); }, 0);
  }

  ngOnDestroy(): void {
    if (this.ticketsCalendarSyncInterval) {
      clearInterval(this.ticketsCalendarSyncInterval);
    }
  }
}
