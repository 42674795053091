// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileInputAccessorModule } from 'file-input-accessor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Layouts
import { RootLayout, CorporateLayout, BlankCorporateComponent, BasicCorporateLayoutComponent } from './@pages/layouts';
// Layout Service - Required
import { pagesToggleService } from './@pages/services/toggler.service';

// Shared Layout Components
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from './@pages/components/shared.module';
import { ShareModule } from '@ngx-share/core';

// Basic Bootstrap Modules
import {
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule,
  CarouselModule,
  BsDatepickerModule
} from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { ProgressModule } from './@pages/components/progress/progress.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgSelectModule } from './@pages/components/select/select.module';
import { pgTagModule } from './@pages/components/tag/tag.module';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { pgSelectfx } from './@pages/components/cs-select/select.module';
import { pgDatePickerModule } from './@pages/components/datepicker/datepicker.module';
import { pgTimePickerModule } from './@pages/components/time-picker/timepicker.module';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxDnDModule } from '@swimlane/ngx-dnd';

// Thirdparty Components / Plugins - Optional
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { MessageModule } from './@pages/components/message/message.module';
import { DataTablesModule } from 'angular-datatables';

import { FullCalendarModule } from '@fullcalendar/angular';

import { Overlay } from '@angular/cdk/overlay';
import { MessageService } from './@pages/components/message/message.service';
import { PushNotificationsModule } from 'ng-push'; // import the module

import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AngularDraggableModule } from 'angular2-draggable';

import { Account } from './providers/account';
import { App } from './providers/app';

import { CoreModule } from './core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';

import { LoginComponent } from './account/login/login';
import { ResetPasswordComponent } from './account/reset_password/reset_password';
import { MyAccountComponent } from './account/edit/my_account';
import { ChangePasswordComponent } from './account/change_password/change_password';

import { JunksComponent } from './junks/junks';
import { SettingsComponent } from './settings/settings';
import { DashboardComponent } from './dashboard/dashboard';

import { UsersComponent } from './users/users.component';
import { AddEditUsersComponent } from './users/add-edit-users/add-edit-users.component';

import { ServicesComponent } from './services/services.component';
import { AddEditServiceComponent } from './services/add-edit-service/add-edit-service.component';

import { SuppliesComponent } from './supplies/supplies.component';
import { AddEditSupplyComponent } from './supplies/add-edit-supply/add-edit-supply.component';

import { CustomersComponent } from './customers/customers.component';
import { AddEditCustomersComponent } from './customers/add-edit-customers/add-edit-customers.component';

import { TicketsComponent } from './tickets/tickets.component';
import { AddEditTicketComponent } from './tickets/add-edit-ticket/add-edit-ticket.component';

import { BillingComponent } from './billing/billing.component';
import { TicketInfoComponent } from './tickets/ticket-info/ticket-info.component';
import { TicketThreadsComponent } from './tickets/ticket-threads/ticket-threads.component';

import { NgxSummernoteModule } from 'ngx-summernote';
import { HttpInterceptorService } from './providers/http-interceptor.service';
import { TicketStatusPipe } from './pipes/ticket-status.pipe';
import { OpeningNotePipe } from './pipes/opening-note.pipe';
import { TimeStampToDatePipe } from './pipes/time-stamp-to-date.pipe';
import { ServiceNamesPipe } from './pipes/service-names.pipe';
import { SupplyNamesPipe } from './pipes/supply-names.pipe';
import { CrewNamesPipe } from './pipes/crew-names.pipe';
import { MapLocationsComponent } from './map-locations/map-locations.component';
// import { DomSanitizerPipe } from './pipes/dom-sanitizer.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    CorporateLayout,
    BlankCorporateComponent,
    BasicCorporateLayoutComponent,
    SidebarComponent, HeaderComponent, HorizontalMenuComponent,
    RootLayout,
    LoginComponent,
    ResetPasswordComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    JunksComponent,
    SettingsComponent,
    DashboardComponent,
    MapLocationsComponent,
    UsersComponent,
    AddEditUsersComponent,
    ServicesComponent,
    AddEditServiceComponent,
    SuppliesComponent,
    AddEditSupplyComponent,
    CustomersComponent,
    AddEditCustomersComponent,
    TicketsComponent,
    BillingComponent,
    TicketInfoComponent,
    TicketThreadsComponent,
    AddEditTicketComponent,
    TicketStatusPipe,
    OpeningNotePipe,
    TimeStampToDatePipe,
    ServiceNamesPipe,
    SupplyNamesPipe,
    CrewNamesPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FileInputAccessorModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // tslint:disable-next-line: deprecation
    HttpModule,
    HttpClientModule,
    SharedModule,
    ProgressModule,
    pgListViewModule,
    pgCardModule,
    pgTabsModule,
    pgSelectModule,
    pgTagModule,
    pgSwitchModule,
    pgSelectfx,
    pgDatePickerModule,
    pgTimePickerModule,
    TextMaskModule,
    RouterModule.forRoot(AppRoutes, { onSameUrlNavigation: 'reload' }),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxDnDModule,
    PerfectScrollbarModule,
    QuillModule,
    MessageModule,
    AngularDraggableModule,
    DataTablesModule,
    FullCalendarModule,
    DashboardModule,
    CoreModule,
    NgxSummernoteModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXjY9wwMauc2t0CzekzOxbkrxJMg2HzKU',
      libraries: ['places']
    }),
    ShareModule.forRoot(),
    PushNotificationsModule
  ],
  providers: [pagesToggleService, Overlay, MessageService, Account, App, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    GoogleMapsAPIWrapper
  ],
  entryComponents: [MapLocationsComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }