import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

import 'rxjs/add/operator/switchMap';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss']
})
export class AddEditUsersComponent implements OnInit {

  @ViewChild('logoutPrompt') logoutPrompt: ModalDirective;

  public crews: any[];

  public userType = 'admin';
  public frmAddEdit: FormGroup;
  public frmAddEditSubmitting: Boolean = false;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    fname: {
      required: 'First Name is required.'
    },
    lname: {
    },
    uname: {
      required: '',
      remote: 'Username already exists.',
    },
    email: {
      required: '',
      email: 'Invalid Email.',
      remote: 'Email already exists.',
    },
    password: {
      required: 'Password is required.'
    },
    phone: {
    },
    address: {
    },
    type: {
      required: 'Type is required.'
    },
    crew: {
      required: 'Type is required.'
    },
    is_temporary: {
    },
    status: {
      required: 'Status is required.'
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    public app: App,
    private account: Account
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userType = params.get('type') ? params.get('type') : this.userType;
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      if (this.recordID) {
        this.getRecordData();
      }
    });

    this.initForm();
    this.getCrews();
  }

  getCrews() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.type = 'crew';
    reqParams.exclude = this.recordID;
    this.helper.makeAPIRequest('users/lookup', reqParams).then(result => {
      if (result.success === 1) {
        this.crews = result.data.records;
      }
    });
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('users/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      fname: [this.record.fname, Validators.compose([Validators.required])],
      lname: [this.record.lname],
      uname: [this.record.uname, Validators.compose([Validators.required]), this.customValidators.remote('users/check_duplicate', 'field_value', { field_name: 'uname', record_id: this.recordID })],
      email: [this.record.email, Validators.compose([Validators.required, Validators.email]), this.customValidators.remote('users/check_duplicate', 'field_value', { field_name: 'email', record_id: this.recordID })],
      password: [this.record.password, this.recordID ? '' : Validators.compose([Validators.required])],
      phone: [this.record.phone],
      address: [this.record.address],
      type: [this.record.type, Validators.compose([Validators.required])],
      crew: [this.record.crew],
      is_temporary: [+this.record.is_temporary],
      status: [(this.record.status) ? this.record.status : 'active', Validators.compose([Validators.required])]
    });
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmAddEdit.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.dynamicValidationUpdates();
    this.validateForm();
  }

  dynamicValidationUpdates() {
    const unameValidators = [];
    this.validationMessages.uname.required = "";
    if (this.frmAddEdit.controls.type.value === 'admin' || this.frmAddEdit.controls.type.value === 'crew') {
      unameValidators.push(Validators.required);
      this.validationMessages.uname.required = "Username is required.";
    }
    this.frmAddEdit.controls['uname'].setValidators(unameValidators);
    this.frmAddEdit.controls['uname'].updateValueAndValidity();
    
    const emailValidators = [Validators.email];
    this.validationMessages.email.required = "";
    if (this.frmAddEdit.controls.type.value === 'admin') {
      emailValidators.push(Validators.required);
      this.validationMessages.email.required = "Email is required.";
    }
    this.frmAddEdit.controls['email'].setValidators(emailValidators);
    this.frmAddEdit.controls['email'].updateValueAndValidity();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save() {
    setTimeout(() => {
      if (!this.frmAddEditSubmitting) {
        this.frmAddEditSubmitting = true;
        if (this.frmAddEdit.valid) {
          const userInputs: any = this.frmAddEdit.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.record_id = this.recordID;
          reqParams.fname = userInputs.fname;
          reqParams.lname = userInputs.lname;
          reqParams.uname = userInputs.uname;
          reqParams.email = userInputs.email;
          reqParams.password = userInputs.password;
          reqParams.phone = userInputs.phone;
          reqParams.address = userInputs.address;
          reqParams.type = userInputs.type;
          reqParams.crew = userInputs.crew;
          reqParams.is_temporary = (userInputs.is_temporary) ? 1 : 0;
          reqParams.status = userInputs.status;

          this.helper.makeAPIRequest('users/save', reqParams).then((response) => {
            this.frmAddEditSubmitting = false;
            if (response.success === 1) {
              this.helper.showNotification('success', 'User info saved!', this.helper.config.defaultSuccessTitle);
              this.helper.navBack();
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else if (response.errorCode === 4) {
                this.helper.showNotification('danger', 'Username already exists.', this.helper.config.defaultErrorTitle);
              } else if (response.errorCode === 5) {
                this.helper.showNotification('danger', 'Email already exists.', this.helper.config.defaultErrorTitle);
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmAddEditSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }

  logout_from_app() {
    this.logoutPrompt.hide();
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = this.recordID;
    this.helper.makeAPIRequest('users/logout_from_app', reqParams).then((response) => {
      if (response.success === 1) {
        this.helper.showNotification('success', 'User logged out from the app.', this.helper.config.defaultSuccessTitle);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }
}
