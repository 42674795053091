import { Routes } from '@angular/router';
// Layouts
import {
  BlankCorporateComponent
} from './@pages/layouts';

import { Account } from './providers/account';


import { LoginComponent } from './account/login/login';
import { ResetPasswordComponent } from './account/reset_password/reset_password';
import { MyAccountComponent } from './account/edit/my_account';
import { ChangePasswordComponent } from './account/change_password/change_password';
import { BasicCorporateLayoutComponent } from './@pages/layouts/basic-corporate/basic-corporate.component';
import { AddEditUsersComponent } from './users/add-edit-users/add-edit-users.component';
import { UsersComponent } from './users/users.component';
import { AddEditServiceComponent } from './services/add-edit-service/add-edit-service.component';
import { ServicesComponent } from './services/services.component';
import { AddEditCustomersComponent } from './customers/add-edit-customers/add-edit-customers.component';
import { CustomersComponent } from './customers/customers.component';
import { JunksComponent } from './junks/junks';
import { SettingsComponent } from './settings/settings';
import { AddEditSupplyComponent } from './supplies/add-edit-supply/add-edit-supply.component';
import { SuppliesComponent } from './supplies/supplies.component';
import { AddEditTicketComponent } from './tickets/add-edit-ticket/add-edit-ticket.component';
import { BillingComponent } from './billing/billing.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketInfoComponent } from './tickets/ticket-info/ticket-info.component';
import { TicketThreadsComponent } from './tickets/ticket-threads/ticket-threads.component';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: BlankCorporateComponent,
    children: [{
      path: '',
      component: LoginComponent
    }]
  },
  {
    path: 'reset_password',
    component: BlankCorporateComponent,
    children: [{
      path: '',
      component: ResetPasswordComponent
    }]
  },
  {
    path: 'account',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: 'change_password',
        component: ChangePasswordComponent
      },
      {
        path: '',
        component: MyAccountComponent
      }
    ]
  },
  {
    path: 'billing',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: BillingComponent
      },
      {
        path: 'tickets/threads',
        component: TicketThreadsComponent,
        outlet: 'page'
      },
      {
        path: 'tickets/info/:id',
        component: TicketInfoComponent,
        outlet: 'page'
      },
      {
        path: 'tickets/edit/:id',
        component: AddEditTicketComponent,
        outlet: 'page'
      },
    ]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: BasicCorporateLayoutComponent,
    canActivate: [Account],
    data: { validations: { account: { type: ['admin'] } } },
    children: [
      {
        path: '',
        component: TicketsComponent
      },
      {
        path: 'junks',
        component: JunksComponent,
        outlet: 'page'
      },
      {
        path: 'settings',
        component: SettingsComponent,
        outlet: 'page'
      },
      {
        path: 'tickets/create',
        outlet: 'page',
        children: [
          {
            path: '',
            component: AddEditTicketComponent
          },
          {
            path: ':date',
            component: AddEditTicketComponent
          },
        ]
      },
      {
        path: 'tickets/threads',
        component: TicketThreadsComponent,
        outlet: 'page'
      },
      {
        path: 'tickets/info/:id',
        component: TicketInfoComponent,
        outlet: 'page'
      },
      {
        path: 'tickets/edit/:id',
        component: AddEditTicketComponent,
        outlet: 'page'
      },
      {
        path: 'customers',
        component: CustomersComponent,
        outlet: 'page'
      },
      {
        path: 'customers/add',
        component: AddEditCustomersComponent,
        outlet: 'page'
      },
      {
        path: 'customers/edit/:id',
        component: AddEditCustomersComponent,
        outlet: 'page'
      },
      {
        path: 'users',
        component: UsersComponent,
        outlet: 'page'
      },
      {
        path: 'users/add',
        component: AddEditUsersComponent,
        outlet: 'page'
      },
      {
        path: 'users/edit/:id',
        component: AddEditUsersComponent,
        outlet: 'page'
      },
      {
        path: 'services',
        component: ServicesComponent,
        outlet: 'page'
      },
      {
        path: 'services/add',
        component: AddEditServiceComponent,
        outlet: 'page'
      },
      {
        path: 'services/edit/:id',
        component: AddEditServiceComponent,
        outlet: 'page'
      },
      {
        path: 'supplies',
        component: SuppliesComponent,
        outlet: 'page'
      },
      {
        path: 'supplies/add',
        component: AddEditSupplyComponent,
        outlet: 'page'
      },
      {
        path: 'supplies/edit/:id',
        component: AddEditSupplyComponent,
        outlet: 'page'
      },
    ]
  }
];