import { Pipe, PipeTransform } from '@angular/core';
import { IdeateHelper } from '../core/providers/ideate';

@Pipe({
  name: 'crewNames'
})
export class CrewNamesPipe implements PipeTransform {

  constructor(private helper: IdeateHelper) { }

  transform(value: any, args?: any): any {
    const crewsSelected = [];
    if (value && value.length > 0) {
      for (const item of value) {
        const crewSelectedIdx = this.helper.getArrayIndex(crewsSelected, 'id', item.id);
        if (crewSelectedIdx === false) {
          const crewSelected = {id: item.id, fname: item.fname, lname: item.lname};
          crewsSelected.push(crewSelected);
        } else {
          crewsSelected[crewSelectedIdx].qty++;
        }
      }
    }

    const crewNames = [];
    if (crewsSelected.length > 0) {
      for (const crew of crewsSelected) {
        const crewName = (crew.fname + ' ' + crew.lname).trim();
        crewNames.push(crewName);
      }
    }
    return (crewNames && crewNames.length > 0) ? crewNames.join(', ') : '';
  }

}
