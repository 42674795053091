import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

@Component({
  templateUrl: './my_account.html'
})
export class MyAccountComponent implements OnInit {

  public frmMyAccount: FormGroup;
  public frmMyAccountSubmitting: Boolean = false;
  public validationMessages: any = {
    fname: {
      required: 'Please enter your First Name.'
    },
    lname: {
    },
    phone: {
      required: 'Please enter your Phone Number.'
    },
    address: {
    },
  };

  constructor(
    public account: Account,
    public app: App,
    public helper: IdeateHelper,
    private router: Router,
    private formBuilder: FormBuilder,
    private customValidators: CustomValidators
  ) { }

  ngOnInit() {

    this.frmMyAccount = this.formBuilder.group({
      fname: [this.account.info.fname, Validators.compose([Validators.required])],
      lname: [this.account.info.lname],
      phone: [this.account.info.phone, Validators.compose([Validators.required])],
      address: [this.account.info.address]
    });
    this.frmMyAccount.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmMyAccount.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmMyAccount, this.validationMessages);
  }

  update_account() {
    setTimeout(() => {
      if (this.frmMyAccount.valid) {
        if (!this.frmMyAccountSubmitting) {
          this.frmMyAccountSubmitting = true;
          const userInputs: any = this.frmMyAccount.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.fname = userInputs.fname;
          reqParams.lname = userInputs.lname;
          reqParams.phone = userInputs.phone;
          reqParams.address = userInputs.address;

          this.helper.makeAPIRequest('account/update', reqParams).then((response) => {
            this.frmMyAccountSubmitting = false;
            if (response.success === 1) {
              this.helper.showNotification('success', 'You just updated your account!', this.helper.config.defaultSuccessTitle);
              this.account.logIn(response.data.userdata);
              this.router.navigate(['/']);
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmMyAccountSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }
}
