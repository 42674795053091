import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';

@Component({
  templateUrl: './login.html'
})
export class LoginComponent implements OnInit {

  public frmLogin: FormGroup;
  public frmLoginSubmitting: Boolean = false;
  private validationMessages: any = {
    username_email: {
      required: 'Please enter your username/email.'
    },
    password: {
      required: 'Please enter your password.'
    },
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: CustomValidators,
    private account: Account) {

  }

  ngOnInit() {

    this.frmLogin = this.formBuilder.group({
      username_email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });

    this.frmLogin.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmLogin.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();

  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmLogin, this.validationMessages);
  }

  login() {

    const userInputs: any = this.frmLogin.value;
    const reqParams: any = {};
    reqParams.email = userInputs.username_email;
    reqParams.password = userInputs.password;

    const errorTitle = 'Login Failed!';
    if (!this.frmLoginSubmitting) {
      this.frmLoginSubmitting = true;
      this.helper.makeAPIRequest('account/login', reqParams).then((response) => {
        this.frmLoginSubmitting = false;
        if (response.success === 1) {
          this.account.logIn(response.data.userdata);
          this.router.navigate(['/']);
        } else if (response.error === 1) {
          if (response.errorCode === 3) {
            this.helper.showNotification('danger', 'Email or password not matched, or account is trashed!', errorTitle);
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', 'Account status is inactive.', errorTitle);
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, errorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, errorTitle);
        }
      }).catch((httpError) => {
        this.frmLoginSubmitting = false;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, errorTitle);
      });
    }
  }
}