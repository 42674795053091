import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

import 'rxjs/add/operator/switchMap';
import { Observable, Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-threads',
  templateUrl: './ticket-threads.component.html',
  styleUrls: ['./ticket-threads.component.scss']
})
export class TicketThreadsComponent implements OnInit, OnDestroy {

  public filterKeyword = '';
  public filterCustomer: any;
  public filterCustomerKeyword = '';
  public filterCustomersOpts: Observable<any>;
  public filterCrew: any;
  public filterCrewKeyword = '';
  public filterCrewsOpts: Observable<any>;
  public filterInputsChangedSub: Subject<string> = new Subject<string>();

  public threads: any = [];
  public threadsLoading = false;
  private threadUpdatesInterval;
  public rootPage = '/dashboard';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account,
    private app: App
  ) {
    let urlPath = router.url;
    if (urlPath.indexOf('/', 1) > 0) {
      urlPath = urlPath.slice(0, urlPath.indexOf('/', 1));
    }
    if (['/dashboard', '/billing'].indexOf(urlPath) > 0) {
      this.rootPage = urlPath;
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.threadUpdatesInterval = setInterval(() => {
        this.getThreadsData(true, false);
      }, 30000);
      this.getThreadsData(false, true);
    });

    this.filterInputsChangedSub
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(model => {
        this.getThreadsData(false, true);
      });
  }

  evFilterInputsChanged(query: any) {
    this.filterInputsChangedSub.next(query);
  }

  evOptsFilterCustomerNGMChanged(event: any): void {
    this.filterCustomersOpts = this.app.getNGMTypeAheadObservableOptions(this.filterCustomerKeyword, 'customers/lookup');
  }
  evOptsCustomerSelected(event: any): void {
    this.filterCustomer = event.item;
  }
  evOptsCustomerChanged(event: any): void {
    setTimeout(() => {
      const currCustomerName = this.filterCustomerKeyword;
      if (currCustomerName === '' || currCustomerName !== this.helper.getObjVal(this.filterCustomer, ['name'], '')) {
        this.filterCustomer = undefined;
        this.filterCustomerKeyword = '';
      }
      this.getThreadsData(false, true);
    }, 100);
  }

  evOptsFilterCrewNGMChanged(event: any): void {
    this.filterCrewsOpts = this.app.getNGMTypeAheadObservableOptions(this.filterCrewKeyword, 'users/lookup', {type: 'crew'});
  }
  evOptsCrewSelected(event: any): void {
    this.filterCrew = event.item;
  }
  evOptsCrewChanged(event: any): void {
    setTimeout(() => {
      const currCrewName = this.filterCrewKeyword;
      if (currCrewName === '' || currCrewName !== this.helper.getObjVal(this.filterCrew, ['name'], '')) {
        this.filterCrew = undefined;
        this.filterCrewKeyword = '';
      }
      this.getThreadsData(false, true);
    }, 100);
  }

  getThreadsData(checkNewMessages: Boolean, clearList: Boolean = false) {
    if (!this.threadsLoading || clearList === true) {
      this.threadsLoading = true;
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.get_new_message = (checkNewMessages) ? 1 : 0;
      if (reqParams.get_new_message) {
        reqParams.offset_thread_id = (this.threads.length > 0) ? this.threads[0].id : 0;
      } else {
        reqParams.filter_keyword = this.filterKeyword;
        reqParams.filter_customer = this.helper.getObjVal(this.filterCustomer, ['id'], 0);
        reqParams.filter_crew = this.helper.getObjVal(this.filterCrew, ['id'], 0);
        reqParams.offset_thread_id = (this.threads.length > 0 && clearList !== true) ? this.threads[this.threads.length - 1].id : 0;
        reqParams.limit = 10;
      }
      this.helper.makeAPIRequest('tickets/threads', reqParams).then((response) => {
        this.threadsLoading = false;
        if (clearList) {
          this.threads = [];
        }
        if (response.success === 1) {
          if (response.data.threads.length > 0) {
            if (reqParams.get_new_message) {
              this.threads = response.data.threads.concat(this.threads);
            } else {
              this.threads = this.threads.concat(response.data.threads);
            }
          }
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.threadsLoading = false;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  ngOnDestroy() {
    if (this.threadUpdatesInterval) {
      clearInterval(this.threadUpdatesInterval);
    }
  }

}