import { Pipe, PipeTransform } from '@angular/core';
import { IdeateHelper } from '../core/providers/ideate';

@Pipe({
  name: 'supplyNames'
})
export class SupplyNamesPipe implements PipeTransform {

  constructor(private helper: IdeateHelper) { }

  transform(value: any, args?: any): any {
    const suppliesSelected = [];
    if (value && value.length > 0) {
      for (const item of value) {
        let supplySelectedIdx: any = false;
        for (let i = 0; i < suppliesSelected.length; i++) {
          if (+item.id === +suppliesSelected[i].id && +item.price === +suppliesSelected[i].price) {
            supplySelectedIdx = i;
          }
        }
        if (supplySelectedIdx === false) {
          const supplySelected = { id: item.id, title: item.title, qty: ((item.qty) ? item.qty : 1), price: (item.price) ? item.price : 0};
          suppliesSelected.push(supplySelected);
        } else {
          suppliesSelected[supplySelectedIdx].qty += item.qty;
        }
      }
    }

    const supplyNames = [];
    if (suppliesSelected.length > 0) {
      for (const supply of suppliesSelected) {
        let supplyName = supply.title;
        supplyName += ' (' + supply.qty + ' x ' + '$' + supply.price + ')';
        supplyNames.push(supplyName);
      }
    }
    return (supplyNames && supplyNames.length > 0) ? supplyNames.join(', ') : '';
  }

}
