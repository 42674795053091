import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-supply',
  templateUrl: './add-edit-supply.component.html',
  styleUrls: ['./add-edit-supply.component.scss']
})
export class AddEditSupplyComponent implements OnInit {

  public frmAddEdit: FormGroup;
  public frmAddEditSubmitting: Boolean = false;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    title: {
      required: 'Title is required.',
      remote: 'Supply already exists.'
    },
    price: {
      required: 'Price is required.',
      min: 'Minimum allowed value is 0.'
    }
  };

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account,
    private app: App
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.getRecordData();
    });

    this.initForm();
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('supplies/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 5) {
            this.helper.showNotification('danger', 'Oops!', 'Supply already exists.');
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      title: [this.record.title, Validators.compose([Validators.required]), this.customValidators.remote('supplies/check_duplicate', 'title', { record_id: this.recordID })],
      price: [((this.record.price) ? this.record.price : '0'), Validators.compose([Validators.required, Validators.min(0)])]
    });
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save() {
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        if (!this.frmAddEditSubmitting) {
          this.frmAddEditSubmitting = true;
          const userInputs: any = this.frmAddEdit.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.record_id = this.recordID;
          reqParams.title = userInputs.title;
          reqParams.price = userInputs.price;

          this.helper.makeAPIRequest('supplies/save', reqParams).then((response) => {
            this.frmAddEditSubmitting = false;
            if (response.success === 1) {
              this.helper.showNotification('success', 'Supply info saved!', this.helper.config.defaultSuccessTitle);
              this.helper.navBack();
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmAddEditSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }
}

