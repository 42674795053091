import { Pipe, PipeTransform } from '@angular/core';
import { App } from '../providers/app';

@Pipe({
  name: 'ticketStatus'
})
export class TicketStatusPipe implements PipeTransform {

  constructor(private app: App) { }

  transform(value: any): any {
    let ticketStatusLabel = '';
    const ticketStatus = this.app.ticketStatuses.filter((item: any) => (item.value === value));
    if (ticketStatus.length === 1) {
      ticketStatusLabel = ticketStatus[0].label;
    }
    return ticketStatusLabel;
  }

}
