import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/providers/ideate';
import { App } from '../providers/app';
import { Account } from '../providers/account';

@Component({
  templateUrl: './junks.html'
})
export class JunksComponent implements OnInit {

  public junkLoadOptionDefault: any;
  public junkLoadOptions: any;
  public junkDumpingLocationOptionDefault: any;
  public junkDumpingLocationOptions: any;

  public frmJunks: FormGroup;
  public frmJunksSubmitting: Boolean = false;
  public settings: any;
  public validationMessages: any = {
  };

  constructor(
    private route: ActivatedRoute,
    public app: App,
    public account: Account,
    public helper: IdeateHelper,
    private router: Router,
    private formBuilder: FormBuilder,
    private customValidators: IdeateValidators
  ) { }

  ngOnInit() {
    this.junkLoadOptionDefault = {size: '', price: 0};
    this.junkLoadOptions = [JSON.parse(JSON.stringify(this.junkLoadOptionDefault))];
    this.junkDumpingLocationOptionDefault = {name: '', location: {address: '', latitude: 0, longitude: 0}};
    this.junkDumpingLocationOptions = [JSON.parse(JSON.stringify(this.junkDumpingLocationOptionDefault))];
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.getSettings();
    });
  }

  getSettings() {
    setTimeout(() => {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      this.helper.makeAPIRequest('settings/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.settings = response.data.site_settings;
          if (!this.helper.isEmptyObj(this.settings.junk_load_options)) {
            this.junkLoadOptions = this.settings.junk_load_options;
          }
          if (!this.helper.isEmptyObj(this.settings.junk_dumping_locations)) {
            this.junkDumpingLocationOptions = this.settings.junk_dumping_locations;
          }
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }, 0);
  }

  initForm() {
    this.frmJunks = this.formBuilder.group({
    });
    this.frmJunks.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmJunks.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  addJunkLoadOptions(idxItem: number) {
    this.junkLoadOptions.splice((idxItem + 1), 0, JSON.parse(JSON.stringify(this.junkLoadOptionDefault)));
  }
  removeJunkLoadOptions(idxItem: number) {
    this.junkLoadOptions.splice(idxItem, 1);
  }

  addJunkDumpingLocationOptions(idxItem: number) {
    this.junkDumpingLocationOptions.splice((idxItem + 1), 0, JSON.parse(JSON.stringify(this.junkDumpingLocationOptionDefault)));
  }
  removeJunkDumpingLocationOptions(idxItem: number) {
    this.junkDumpingLocationOptions.splice(idxItem, 1);
  }
  updateJunkDumpingLocationAddress(address: string, idxItem: number) {
    this.junkDumpingLocationOptions[idxItem].location.address = address;
  }
  updateJunkDumpingLocationLatLong(location: any, idxItem: number) {
    this.junkDumpingLocationOptions[idxItem].location.latitude = location.latitude;
    this.junkDumpingLocationOptions[idxItem].location.longitude = location.longitude;
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmJunks, this.validationMessages);
  }

  save() {
    setTimeout(() => {
      if (this.frmJunks.valid) {
        if (!this.frmJunksSubmitting) {
          this.frmJunksSubmitting = true;
          const userInputs: any = this.frmJunks.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.junk_load_options = JSON.stringify(this.junkLoadOptions);
          reqParams.junk_dumping_locations = JSON.stringify(this.junkDumpingLocationOptions);

          this.helper.makeAPIRequest('settings/save_junks', reqParams).then((response) => {
            this.frmJunksSubmitting = false;
            if (response.success === 1) {
              localStorage.setItem('siteSettings', JSON.stringify(response.data.site_settings));
              this.helper.showNotification('success', 'Junk settings saved!', this.helper.config.defaultSuccessTitle);
              this.router.navigate(['/']);
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmJunksSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }
}
