import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators as CustomValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';

@Component({
  templateUrl: './reset_password.html'
})
export class ResetPasswordComponent implements OnInit {

  public logoURL: string;
  public frmResetPassword: FormGroup;
  public frmResetPasswordSubmitting: Boolean = false;
	private validationMessages: any = {
		email: {
		  required: 'Please enter your email.',
		  email: 'Please enter an appropriate email.'
		}
	};

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: CustomValidators,
    private account: Account) {

  }

  ngOnInit() {

    this.frmResetPassword = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });

    this.frmResetPassword.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmResetPassword.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();

  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmResetPassword, this.validationMessages);
  }

	reset_password() {

    const userInputs: any = this.frmResetPassword.value;

    const reqParams: any = {};
    reqParams.email = userInputs.email;

    if (!this.frmResetPasswordSubmitting) {
      this.frmResetPasswordSubmitting = true;
      this.helper.makeAPIRequest('account/reset_password', reqParams).then((response) => {
        this.frmResetPasswordSubmitting = false;
        if (response.success === 1) {
          this.helper.showNotification('success', 'Please check your inbox for the new password.', this.helper.config.defaultSuccessTitle);
          this.router.navigate(['login']);
        } else if (response.error === 1) {
          if (response.errorCode === 3) {
            this.helper.showNotification('danger', 'Email doesn\'t exists!', this.helper.config.defaultErrorTitle);
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', 'Inactive Account!. Please contact the administrator.', this.helper.config.defaultErrorTitle);
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.frmResetPasswordSubmitting = false;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }

	}

}