import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../core/providers/ideate';
import { App } from '../providers/app';
import { Account } from '../providers/account';

@Component({
  templateUrl: './settings.html'
})
export class SettingsComponent implements OnInit {

  public frmSettings: FormGroup;
  public frmAddEditSubmitting: Boolean = false;
  public settings: any;
  public validationMessages: any = {
    travel_billing_radius: {
      required: 'Travel billing radius is required.'
    },
    sales_tax: {
      required: 'Sales Tax is required.'
    },
    hourly_rate: {
      required: 'Hourly Rate is required.'
    }
  };

  constructor(
    private route: ActivatedRoute,
    public app: App,
    public account: Account,
    public helper: IdeateHelper,
    private router: Router,
    private formBuilder: FormBuilder,
    private customValidators: IdeateValidators
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.getSettings();
    });
  }

  getSettings() {
    setTimeout(() => {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      this.helper.makeAPIRequest('settings/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.settings = response.data.site_settings;
          this.settings.base_location = (this.settings.base_location) ? this.settings.base_location : {address: '', latitude: '', longitude: '' };
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }, 0);
  }

  initForm() {
    this.frmSettings = this.formBuilder.group({
      travel_billing_radius: [this.settings.travel_billing_radius, Validators.compose([Validators.required])],
      sales_tax: [this.settings.sales_tax, Validators.compose([Validators.required])],
      hourly_rate: [this.settings.hourly_rate, Validators.compose([Validators.required])],
    });
    this.frmSettings.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.frmSettings.statusChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmSettings, this.validationMessages);
  }

  updateBaseLocationAddress(address: string) {
    this.settings.base_location.address = address;
  }

  updateBaseLocationLatLong(location: any) {
    this.settings.base_location.latitude = location.latitude;
    this.settings.base_location.longitude = location.longitude;
  }

  save() {
    setTimeout(() => {
      if (this.frmSettings.valid) {
        if (!this.frmAddEditSubmitting) {
          this.frmAddEditSubmitting = true;
          const userInputs: any = this.frmSettings.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.base_location = JSON.stringify({ address: this.settings.base_location.address, latitude: this.settings.base_location.latitude, longitude: this.settings.base_location.longitude });
          reqParams.travel_billing_radius = userInputs.travel_billing_radius;
          reqParams.sales_tax = userInputs.sales_tax;
          reqParams.hourly_rate = userInputs.hourly_rate;

          this.helper.makeAPIRequest('settings/save', reqParams).then((response) => {
            this.frmAddEditSubmitting = false;
            if (response.success === 1) {
              localStorage.setItem('siteSettings', JSON.stringify(response.data.site_settings));
              this.helper.showNotification('success', 'Settings saved!', this.helper.config.defaultSuccessTitle);
              this.router.navigate(['/']);
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmAddEditSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }
}
