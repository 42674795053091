import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdeateHelper, IdeateValidators } from './providers/ideate';
import { GoogleMapSearchComponent } from './components/google-map-search/google-map-search.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { MediaGalleryComponent } from './components/media-gallery/media-gallery.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { pgCardModule } from '../@pages/components/card/card.module';
import { pgSelectModule } from '../@pages/components/select/select.module';
import { CheckboxTrueFalseValueDirective } from './directives/checkbox-true-false-value.directive';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { DomSanitizerPipe } from './../pipes/dom-sanitizer.pipe';
import { ShareModule } from '@ngx-share/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    pgCardModule,
    pgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXjY9wwMauc2t0CzekzOxbkrxJMg2HzKU',
      libraries: ['places']
    }),
    ShareModule.forRoot(),
  ],
  declarations: [GoogleMapSearchComponent, TimePickerComponent, MediaGalleryComponent, CheckboxTrueFalseValueDirective, FocusInvalidInputDirective, DomSanitizerPipe],
  providers: [IdeateHelper, IdeateValidators, GoogleMapsAPIWrapper],
  exports: [GoogleMapSearchComponent, TimePickerComponent, MediaGalleryComponent, CheckboxTrueFalseValueDirective, FocusInvalidInputDirective, DomSanitizerPipe, ShareModule]
})
export class CoreModule { }
