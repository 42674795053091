import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

import 'rxjs/add/operator/switchMap';

import { ModalDirective } from 'ngx-bootstrap';
import { FullCalendarComponent } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'app-ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.scss']
})
export class TicketInfoComponent implements OnInit {

  @ViewChild('threadMediaGalleryModal') threadMediaGalleryModal: ModalDirective;
  public threadMediaFiles = [];
  public activeMediaFileName = '';

  @ViewChild('mediaBeforeStartGalleryModal') mediaBeforeStartGalleryModal: ModalDirective;
  public mediaBeforeStartFiles = [];
  public activeMediaBeforeStartFileName = '';

  @ViewChild('mediaAfterStartGalleryModal') mediaAfterStartGalleryModal: ModalDirective;
  public mediaAfterStartFiles = [];
  public activeMediaAfterStartFileName = '';

  @ViewChild('ticketInfoCalendar') public ticketInfoCalendar: FullCalendarComponent;
  public ticketInfoCalendarPlugins = [dayGridPlugin];
  public ticketInfoCalendarAPI;
  public ticketInfoCalendarEvents = [];

  public recordID = 0;
  public record: any = {};
  public rootPage = '/dashboard';
  public actionInProgress: Boolean = false;
  public sendingInvoice: Boolean = false;
  public fetchBillingMethodData: Boolean = false;
  public showBillingMethodData: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account,
    private app: App
  ) {
    let urlPath = router.url;
    if (urlPath.indexOf('/', 1) > 0) {
      urlPath = urlPath.slice(0, urlPath.indexOf('/', 1));
    }
    if (['/dashboard', '/billing'].indexOf(urlPath) > 0) {
      this.rootPage = urlPath;
      if (urlPath === '/billing') {
        this.fetchBillingMethodData = true;
      }
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.recordID = (params.get('id')) ? +params.get('id') : +this.recordID;
      if (this.recordID > 0) {
        this.getRecordData();
      }
    });
  }

  getRecordData() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = this.recordID;
    reqParams.get_billing_method_data = +this.fetchBillingMethodData;
    reqParams.get_threads = 1;
    this.helper.makeAPIRequest('tickets/get', reqParams).then((response) => {
      if (response.success === 1) {
        this.record = response.data.record;
        setTimeout(() => {
          const timezoneOffsetMS = new Date().getTimezoneOffset() * 60 * 1000;
          const threadMediaFilesTmp = [];
          const mediaBeforeFilesTmp = [];
          const mediaAfterFilesTmp = [];
          this.record.threads.filter((threadItem) => (threadItem.media_videos.length > 0 || threadItem.media_images.length > 0)).forEach((threadItem) => {
            if (threadItem.media_videos.length > 0) {
              threadItem.media_videos.forEach(file => {
                const mediaFile = {
                  name: file.filename,
                  url: file.url,
                  thumbnail: '',
                  type: 'video',
                  caption: threadItem.message
                };
                threadMediaFilesTmp.push(mediaFile);
                if (threadItem.media_source === 'media_before_started') {
                  mediaBeforeFilesTmp.push(mediaFile);
                } else {
                  mediaAfterFilesTmp.push(mediaFile);
                }
              });
            }
            if (threadItem.media_images.length > 0) {
              threadItem.media_images.forEach(file => {
                const mediaFile = {
                  name: file.filename,
                  url: file.url,
                  thumbnail: file.thumbnail,
                  type: 'image',
                  caption: threadItem.message
                };
                threadMediaFilesTmp.push(mediaFile);
                if (threadItem.media_source === 'media_before_started') {
                  mediaBeforeFilesTmp.push(mediaFile);
                } else {
                  mediaAfterFilesTmp.push(mediaFile);
                }
              });
            }
          });
          this.threadMediaFiles = threadMediaFilesTmp;
          this.mediaBeforeStartFiles = mediaBeforeFilesTmp;
          this.mediaAfterStartFiles = mediaAfterFilesTmp;
          this.ticketInfoCalendarEvents = [];
          this.record.working_days.forEach((workingDay: any) => {
            const event: any = { start: '', end: ''};
            event.start = new Date(workingDay);
            event.start = new Date(event.start.getTime() + timezoneOffsetMS);
            event.end = new Date(workingDay);
            event.end = new Date(event.end.getTime() + timezoneOffsetMS);
            this.ticketInfoCalendarEvents.push(event);
          });
          this.ticketInfoCalendarAPI = this.ticketInfoCalendar.getApi();
        }, 100);
      } else if (response.error === 1) {
        if (response.errorCode === 2) {
          this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
          this.account.logOut();
        } else {
          this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      } else {
        this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
      }
    }).catch((httpError) => {
      this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
    });
  }

  sendInvoice(type = '') {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.record_id = this.recordID;
    reqParams.type = type;
    if (!this.sendingInvoice) {
      this.sendingInvoice = true;
      this.helper.makeAPIRequest('tickets/send_invoice', reqParams).then((response) => {
        this.sendingInvoice = false;
        if (response.success === 1) {
          this.helper.showNotification('success', 'Invoice sent successfully!', this.helper.config.defaultSuccessTitle);
          // this.helper.navBack();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.sendingInvoice = false;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  takeActionOnTicket(message: string = '', type: string, modal: ModalDirective) {
    message = message.trim();
    if (type !== 'message' || (type === 'message' && message !== '')) {
      this.actionInProgress = true;
      if (modal) {
        modal.hide();
      }

      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      reqParams.type = type;
      reqParams.message = message;
      reqParams.get_billing_method_data = +this.fetchBillingMethodData;
      reqParams.get_threads = 1;
      this.helper.makeAPIRequest('tickets/respond', reqParams).then((response) => {
        this.actionInProgress = false;
        if (response.success === 1) {
          this.helper.showNotification('success', 'Ticket updated successfully!', this.helper.config.defaultSuccessTitle);
          this.record = response.data.record;
          this.app.reloadTickets.emit('ticket_saved');
          // this.helper.navBack();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.actionInProgress = false;
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
      return true;
    }
    return false;
  }

  showThreadMediaGalleryModal(mediaFileName) {
    this.threadMediaGalleryModal.show();
    this.activeMediaFileName = mediaFileName;
  }

  showMediaBeforeStartGalleryModal(mediaFileName) {
    this.mediaBeforeStartGalleryModal.show();
    this.activeMediaBeforeStartFileName = mediaFileName;
  }

  showMediaAfterStartGalleryModal(mediaFileName) {
    this.mediaAfterStartGalleryModal.show();
    this.activeMediaAfterStartFileName = mediaFileName;
  }

}