import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';

@Component({
  selector: 'app-basic-corporate-layout',
  templateUrl: './basic-corporate.component.html',
  styleUrls: ['./basic-corporate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicCorporateLayoutComponent extends RootLayout implements OnInit {

  menuLinks = [
    {
      label: 'Tickets',
      routerLink: ['/dashboard']
    },
    {
      label: 'Billing',
      routerLink: ['/billing']
    },
    {
      label: 'Customers',
      routerLink: ['/dashboard', { outlets: { page: ['customers'] } }]
    },
    {
      label: 'Users',
      routerLink: ['/dashboard', { outlets: { page: ['users'] } }]
    },
    {
      label: 'Services',
      routerLink: ['/dashboard', { outlets: { page: ['services'] } }]
    },
    {
      label: 'Supplies',
      routerLink: ['/dashboard', { outlets: { page: ['supplies'] } }]
    },
    {
      label: 'Junks',
      routerLink: ['/dashboard', { outlets: { page: ['junks'] } }]
    },
    {
      label: 'Settings',
      routerLink: ['/dashboard', { outlets: { page: ['settings'] } }]
    }
  ];

  ngOnInit() {
    this.changeLayout('menu-pin');
    this.changeLayout('menu-behind');
    // Will sidebar close on screens below 1024
    this.autoHideMenuPin();
  }
}
