import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-ticket',
  templateUrl: './add-edit-ticket.component.html',
  styleUrls: ['./add-edit-ticket.component.scss']
})
export class AddEditTicketComponent implements OnInit {

  public customerProtoType = { id: 0, name: '', address: '', latitude: 0, longitude: 0, ste: '', door_code: '', phone: [], email: [], site_managers: []};
  public customer: any;
  public customersOpts: Observable<any>;
  public customerCardsSaved: any[] = [];
  public customerSiteManagers: any[] = [];
  public siteManagers: any[] = [];
  public services: any[] = [];
  public servicesLoaded = false;
  public supplies: any[] = [];
  public suppliesLoaded = false;
  public crews: any[] = [];
  public crewsLoaded = false;
  public showAdditionalSupplies = false;

  public frmAddEdit: FormGroup;
  public frmAddEditSubmitting: Boolean = false;
  public recordID = 0;
  public recordCreateDate = '';
  public record: any = {};
  public recordDataRequestInterval: any = false;
  public validationMessages: any = {
    customer: {
    },
    customer_id: {
    },
    customer_name: {
      required: 'Name is required.'
    },
    customer_address: {
    },
    customer_latitude: {
    },
    customer_longitude: {
    },
    customer_ste: {
    },
    customer_door_code: {
    },
    site_managers: {},
    address: {
    },
    latitude: {
    },
    longitude: {
    },
    ste: {
    },
    door_code: {
    },
    duration_hours: {
    },
    duration_days: {
    },
    square_footage: {
    },
    price: {
      required: 'Service Price is required.'
    },
    pricing_to_be_calculated_on_site: {
    },
    pricing_to_be_calculated_for_travelling: {
    },
    billing_status: {
    },
    billing_method: {
    },
    billing_card: {
    },
    billing_card_number: {
      pattern: 'Invalid card number.'
    },
    billing_card_expiry: {
      pattern: 'Invalid expiry.'
    },
    billing_card_cvv: {
      pattern: 'Invalid CVV.'
    },
    media_files: {
    },
    note: {
    },
    schedule_date_from: {
    },
    schedule_date_to: {
    },
    schedule_time: {
    },
    send_contract_to_customer: {
    },
  };

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account,
    public app: App
  ) { }

  ngOnInit() {
    this.getServices();
    this.getSupplies();
    this.getCrews();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.recordCreateDate = (params.get('date')) ? params.get('date') : '';
      this.recordDataRequestInterval = setInterval(() => {
        if (this.servicesLoaded && this.suppliesLoaded && this.crewsLoaded) {
          this.getRecordData();
          if (this.recordDataRequestInterval) {
            clearInterval(this.recordDataRequestInterval);
          }
        }
      });
    });
    // this.initForm();
  }

  evOptsCustomerSelected(event: any): void {
    this.selectCustomer(event.item);
  }
  evOptsCustomerChanged(event: any): void {
    setTimeout(() => {
      const currCustomerID = this.frmAddEdit.controls['customer'].value;
      if (currCustomerID !== '' && currCustomerID !== this.helper.getObjVal(this.record.customer, ['name'], '')) {
        this.frmAddEdit.controls['customer'].setValue('');
        if (!this.helper.isEmptyObj(this.record.customer)) {
          this.selectCustomer();
        } else {
          this.customerCardsSaved = [];
          this.customerSiteManagers = [];
          this.siteManagers = [];
          this.addCustomerSiteManagerFieldGroup();
        }
      } else {
        if ((!this.customerSiteManagers || this.customerSiteManagers.length <= 0) && (!this.siteManagers || this.siteManagers.length <= 0)) {
          this.addCustomerSiteManagerFieldGroup();
        }
      }
    }, 100);
  }

  evCustomerAddressChanged(address: string) {
    this.record.customer.address = address;
    this.frmAddEdit.controls['customer_address'].setValue(this.helper.getObjVal(this.record, ['customer', 'address'], ''));
    this.evCustomerAddressFieldsChanged();
  }
  evCustomerLatLongChanged(latLong: any) {
    this.record.customer.latitude = latLong.latitude;
    this.record.customer.longitude = latLong.longitude;
    this.frmAddEdit.controls['customer_latitude'].setValue(this.helper.getObjVal(this.record, ['customer', 'latitude'], 0));
    this.frmAddEdit.controls['customer_longitude'].setValue(this.helper.getObjVal(this.record, ['customer', 'longitude'], 0));
    this.evCustomerAddressFieldsChanged();
  }
  evCustomerAddressFieldsChanged() {
    /* this.record.address = this.record.customer.address;
    this.record.latitude = this.record.customer.latitude;
    this.record.longitude = this.record.customer.longitude;
    this.frmAddEdit.controls['address'].setValue(this.record.address);
    this.frmAddEdit.controls['latitude'].setValue(this.record.latitude);
    this.frmAddEdit.controls['longitude'].setValue(this.record.longitude);
    this.frmAddEdit.controls['ste'].setValue(this.frmAddEdit.controls['customer_ste'].value);
    this.frmAddEdit.controls['door_code'].setValue(this.frmAddEdit.controls['customer_door_code'].value); */
  }

  evAddressChanged(address: string) {
    this.record.address = address;
    this.frmAddEdit.controls['address'].setValue(this.helper.getObjVal(this.record, ['address'], ''));
  }
  evLatLongChanged(latLong: any) {
    this.record.latitude = latLong.latitude;
    this.record.longitude = latLong.longitude;
    this.frmAddEdit.controls['latitude'].setValue(this.helper.getObjVal(this.record, ['latitude'], 0));
    this.frmAddEdit.controls['longitude'].setValue(this.helper.getObjVal(this.record, ['longitude'], 0));
    this.evPricingCalculationPolicyChanged();
  }

  isInvalidEndDate = (current: Date): boolean => {
    const startDate = this.frmAddEdit.controls['schedule_date_from'].value;
    if (startDate !== '' && current < new Date(startDate)) {
      return true;
    }
    return false;
  }

  getServices() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    this.helper.makeAPIRequest('services/lookup', reqParams).then(result => {
      if (result.success === 1) {
        for (const service of result.data.records){
          service.isSelected = false;
          service.isFilteredIn = true;
          this.services.push(service);
        }
      }
      this.servicesLoaded = true;
    });
  }
  filterServices(keyword) {
    keyword = keyword.toLowerCase();
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].title.toLowerCase().indexOf(keyword) >= 0) {
        this.services[i].isFilteredIn = true;
      } else {
        this.services[i].isFilteredIn = false;
      }
    }
  }
  evServicesChange() {
    this.updateServiceSupplies();
  }

  getSupplies() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    this.helper.makeAPIRequest('supplies/lookup', reqParams).then(result => {
      if (result.success === 1) {
        for (const tmpSupply of result.data.records){
          const supply: any = {};
          supply.id = tmpSupply.value;
          supply.title = tmpSupply.label;
          supply.qty_owned = 0;
          supply.qty_shopped = 0;
          supply.qty_owned_updated = 0;
          supply.qty_shopped_updated = 0;
          supply.isSelected = false;
          supply.isServiceSelected = false;
          supply.isFilteredIn = true;
          supply.price_owned = tmpSupply.price;
          supply.price_shopped = tmpSupply.price;
          supply.note = '';
          this.supplies.push(supply);
        }
      }
      this.suppliesLoaded = true;
    });
    this.updateServiceSupplies();
  }
  filterSupplies(keyword) {
    keyword = keyword.toLowerCase();
    for (let i = 0; i < this.supplies.length; i++) {
      if (this.supplies[i].title.toLowerCase().indexOf(keyword) >= 0) {
        this.supplies[i].isFilteredIn = true;
      } else {
        this.supplies[i].isFilteredIn = false;
      }
    }
  }
  updateServiceSupplies() {
    for (let i = 0; i < this.supplies.length; i++) {
      this.supplies[i].isServiceSelected = false;
    }
    const servicesSelected = this.services.filter((item) => (item.isSelected));
    if (servicesSelected.length > 0) {
      for (const service of servicesSelected){
        for (let i = 0; i < this.supplies.length; i++) {
          if (service.supplies.filter((item) => (+item === +this.supplies[i].id)).length > 0) {
            this.supplies[i].isServiceSelected = true;
          }
        }
      }
    }
  }

  getCrews() {
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.type = 'crew';
    this.helper.makeAPIRequest('users/lookup', reqParams).then(result => {
      if (result.success === 1) {
        for (const crew of result.data.records){
          crew.isSelected = false;
          crew.isFilteredIn = true;
          this.crews.push(crew);
        }
      }
      this.crewsLoaded = true;
    });
  }
  filterCrews(keyword) {
    keyword = keyword.toLowerCase();
    for (let i = 0; i < this.crews.length; i++) {
      if (this.crews[i].name.toLowerCase().indexOf(keyword) >= 0) {
        this.crews[i].isFilteredIn = true;
      } else {
        this.crews[i].isFilteredIn = false;
      }
    }
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
      if (this.recordCreateDate !== '') {
        setTimeout(() => {
          this.frmAddEdit.controls['schedule_date_from'].setValue(this.helper.dtFormatFromStrToObj(this.helper.dFormatFromStr(this.recordCreateDate)));
          this.frmAddEdit.controls['schedule_date_to'].setValue(this.helper.dtFormatFromStrToObj(this.helper.dFormatFromStr(this.recordCreateDate)));
        });
      }
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      reqParams.get_threads = 0;
      this.helper.makeAPIRequest('tickets/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          for (let i = 0; i < this.record.services.length; i++) {
            let serviceExists = false;
            for (let j = 0; j < this.services.length; j++) {
              if (+this.services[j].id === +this.record.services[i].id) {
                serviceExists = true;
                this.services[j].isSelected = true;
              }
            }
            if (!serviceExists) {
              const service: any = {};
              service.id = this.record.services[i].id;
              service.title = this.record.services[i].title;
              service.icon = this.record.services[i].icon;
              service.supplies = [];
              service.isSelected = true;
              service.isFilteredIn = true;
              this.services.push(service);
            }
          }
          for (let i = 0; i < this.record.supplies.length; i++) {
            let supplyExist = false;
            for (let j = 0; j < this.supplies.length; j++) {
              if (+this.supplies[j].id === +this.record.supplies[i].id) {
                supplyExist = true;
                this.supplies[j].isSelected = true;
                this.supplies[j].qty_owned = +this.record.supplies[i].qty_owned;
                // this.supplies[j].qty_owned_updated = +this.record.supplies[i].qty_owned;
                // this.supplies[j].price_owned = +this.record.supplies[i].price_owned;
                this.supplies[j].qty_shopped = +this.record.supplies[i].qty_shopped;
                // this.supplies[j].qty_shopped_updated = +this.record.supplies[i].qty_shopped;
                // this.supplies[j].price_shopped = +this.record.supplies[i].price_shopped;
              }
            }
            if (!supplyExist) {
              const supply: any = {};
              supply.id = this.record.supplies[i].id;
              supply.title = this.record.supplies[i].title;
              supply.qty_owned = +this.record.supplies[i].qty_owned;
              // supply.qty_owned_updated = +this.record.supplies[i].qty_owned;
              // supply.price_owned = +this.record.supplies[i].price_owned;
              supply.qty_shopped = +this.record.supplies[i].qty_shopped;
              // supply.qty_shopped_updated = +this.record.supplies[i].qty_shopped;
              // supply.price_shopped = +this.record.supplies[i].price_shopped;
              supply.isSelected = true;
              supply.isServiceSelected = false;
              supply.isFilteredIn = true;
              supply.note = '';
              this.supplies.push(supply);
            }
          }
          for (let i = 0; i < this.record.crews.length; i++) {
            let crewExists = false;
            for (let j = 0; j < this.crews.length; j++) {
              if (+this.crews[j].id === +this.record.crews[i].id) {
                crewExists = true;
                this.crews[j].isSelected = true;
              }
            }
            if (!crewExists) {
              const crew: any = {};
              crew.id = this.record.crews[i].id;
              crew.fname = this.record.crews[i].fname;
              crew.lname = this.record.crews[i].lname;
              crew.name = (crew.fname + ' ' + crew.lname).trim();
              crew.isSelected = true;
              crew.isFilteredIn = true;
              this.crews.push(crew);
            }
          }
          for (let i = 0; i < this.record.media_files.length; i++) {
            this.record.media_files[i].doRemove = false;
          }
          this.customerCardsSaved = this.record.customer.cards_saved;
          this.customerSiteManagers = this.record.customer.site_managers;
          if (this.customerSiteManagers.length && this.customerSiteManagers.length > 0) {
            for (let i = 0; i < this.customerSiteManagers.length; i++) {
              this.customerSiteManagers[i].name = this.customerSiteManagers[i].name.replaceAll(/&#039;/g, '\'');
            }
          }
          this.siteManagers = this.record.site_managers;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  selectCustomer(customer = {}) {
    this.record.customer = this.helper.cloneObj(this.customerProtoType);
    this.customerCardsSaved = [];
    this.customerSiteManagers = [];
    if (!this.helper.isEmptyObj(customer)) {
      this.record.customer = customer;
      this.customerCardsSaved = this.record.customer.cards_saved;
      this.customerSiteManagers = this.record.customer.site_managers;
      if (this.customerSiteManagers.length && this.customerSiteManagers.length > 0) {
        for (let i = 0; i < this.customerSiteManagers.length; i++) {
          this.customerSiteManagers[i].name = this.customerSiteManagers[i].name.replaceAll(/&#039;/g, '\'');
        }
      }
    }
    this.frmAddEdit.controls['customer'].setValue(this.helper.getObjVal(this.record, ['customer', 'name'], ''));
    this.frmAddEdit.controls['customer_id'].setValue(this.helper.getObjVal(this.record, ['customer', 'id'], 0));
    this.frmAddEdit.controls['customer_name'].setValue(this.helper.getObjVal(this.record, ['customer', 'name'], ''));
    this.frmAddEdit.controls['customer_address'].setValue(this.helper.getObjVal(this.record, ['customer', 'address'], ''));
    this.frmAddEdit.controls['customer_latitude'].setValue(this.helper.getObjVal(this.record, ['customer', 'latitude'], 0));
    this.frmAddEdit.controls['customer_longitude'].setValue(this.helper.getObjVal(this.record, ['customer', 'longitude'], 0));
    this.frmAddEdit.controls['customer_ste'].setValue(this.helper.getObjVal(this.record, ['customer', 'ste'], ''));
    this.frmAddEdit.controls['customer_door_code'].setValue(this.helper.getObjVal(this.record, ['customer', 'door_code'], ''));
    this.frmAddEdit.controls['site_managers'].setValue('0');
    const formControlsCustomerPhone = <FormArray>this.frmAddEdit.controls['customer_phone'];
    while (formControlsCustomerPhone.length !== 0) {
      this.removeCustomerPhoneFieldGroup(0);
    }
    if (this.record && this.record.customer && this.record.customer.phone && this.record.customer.phone.length > 0) {
      for (let i = 0; i < this.record.customer.phone.length; i++) {
        const phone = this.record.customer.phone[i];
        this.addCustomerPhoneFieldGroup(phone);
      }
    } else {
      this.addCustomerPhoneFieldGroup();
    }
    const formControlsCustomerEmail = <FormArray>this.frmAddEdit.controls['customer_email'];
    while (formControlsCustomerEmail.length !== 0) {
      this.removeCustomerEmailFieldGroup(0);
    }
    if (this.record && this.record.customer && this.record.customer.email && this.record.customer.email.length > 0) {
      for (let i = 0; i < this.record.customer.email.length; i++) {
        const email = this.record.customer.email[i];
        this.addCustomerEmailFieldGroup(email);
      }
    } else {
      this.addCustomerEmailFieldGroup();
    }
    if ((!this.customerSiteManagers || this.customerSiteManagers.length <= 0) && (!this.siteManagers || this.siteManagers.length <= 0)) {
      this.addCustomerSiteManagerFieldGroup();
    }
    this.evCustomerAddressFieldsChanged();
  }

  initForm() {
    let durationHours: any = +this.helper.getObjVal(this.record, ['duration_hours'], 0);
    durationHours = (durationHours === 0) ? '' : durationHours;
    let durationDays: any = +this.helper.getObjVal(this.record, ['duration_days'], 0);
    durationDays = (durationDays === 0) ? '' : durationDays;
    let squareFootage: any = +this.helper.getObjVal(this.record, ['square_footage'], 0);
    squareFootage = (squareFootage === 0) ? '' : squareFootage;
    let price: any = +this.helper.getObjVal(this.record, ['price'], 0);
    price = (price === 0) ? '' : price;
    this.frmAddEdit = this.formBuilder.group({
      customer: [this.helper.getObjVal(this.record, ['customer', 'name'], '')],
      customer_id: [this.helper.getObjVal(this.record, ['customer', 'id'], 0)],
      customer_name: [this.helper.getObjVal(this.record, ['customer', 'name'], ''), Validators.compose([Validators.required])],
      customer_address: [this.helper.getObjVal(this.record, ['customer', 'address'], '')],
      customer_latitude: [this.helper.getObjVal(this.record, ['customer', 'latitude'], 0), Validators.compose([Validators.required])],
      customer_longitude: [this.helper.getObjVal(this.record, ['customer', 'longitude'], 0), Validators.compose([Validators.required])],
      customer_ste: [this.helper.getObjVal(this.record, ['customer', 'ste'], '')],
      customer_door_code: [this.helper.getObjVal(this.record, ['customer', 'door_code'], '')],
      customer_phone: this.formBuilder.array([]),
      customer_email: this.formBuilder.array([]),
      site_managers: [(!this.helper.isEmptyObj(this.record) && !this.helper.isEmptyObj(this.record.site_managers) && this.record.site_managers.length > 0) ? this.record.site_managers[0].id : '0'],
      address: [this.helper.getObjVal(this.record, ['address'], '')],
      latitude: [this.helper.getObjVal(this.record, ['latitude'], 0)],
      longitude: [this.helper.getObjVal(this.record, ['longitude'], 0)],
      ste: [this.helper.getObjVal(this.record, ['ste'], '')],
      door_code: [this.helper.getObjVal(this.record, ['door_code'], '')],
      duration_hours: [durationHours],
      duration_days: [durationDays],
      square_footage: [squareFootage],
      price: [price],
      pricing_to_be_calculated_on_site: [((typeof this.record.pricing_to_be_calculated_on_site !== 'undefined') ? +this.record.pricing_to_be_calculated_on_site : 1)],
      pricing_to_be_calculated_for_travelling: [((typeof this.record.pricing_to_be_calculated_for_travelling !== 'undefined') ? +this.record.pricing_to_be_calculated_for_travelling : 0)],
      billing_status: [this.helper.getObjVal(this.record, ['billing_status'], 'pending_invoice')],
      billing_method: [this.helper.getObjVal(this.record, ['billing_method'], 'cash')],
      billing_card: [+this.helper.getObjVal(this.record, ['billing_card', 'id'], 0)],
      billing_card_number: ['', Validators.compose([Validators.pattern('[0-9 ]{15,19}$')])],
      billing_card_expiry: ['', Validators.compose([Validators.pattern('(0[1-9]|10|11|12)/[0-9]{2}$')])],
      billing_card_cvv: ['', Validators.compose([Validators.pattern('[0-9]{3,4}$')])],
      media_files: [''],
      note: [this.record.note],
      schedule_date_from: [(this.record.schedule_date_from && this.record.schedule_date_from !== '' && this.record.schedule_date_from !== '0000-00-00') ? this.helper.dtFormatFromStrToObj(this.helper.dFormatFromStr(this.record.schedule_date_from)) : ''],
      schedule_date_to: [(this.record.schedule_date_to && this.record.schedule_date_to !== '' && this.record.schedule_date_to !== '0000-00-00') ? this.helper.dtFormatFromStrToObj(this.helper.dFormatFromStr(this.record.schedule_date_to)) : ''],
      schedule_time: [this.record.schedule_time ? this.helper.tFormatFromStr(this.record.schedule_time) : this.helper.tFormatFromStr('00:00')],
      send_contract_to_customer: [''],
    });
    if (this.record && this.record.customer && this.record.customer.phone && this.record.customer.phone.length > 0) {
      for (let i = 0; i < this.record.customer.phone.length; i++) {
        const phone = this.record.customer.phone[i];
        this.addCustomerPhoneFieldGroup(phone);
      }
    } else {
      this.addCustomerPhoneFieldGroup();
    }
    if (this.record && this.record.customer && this.record.customer.email && this.record.customer.email.length > 0) {
      for (let i = 0; i < this.record.customer.email.length; i++) {
        const email = this.record.customer.email[i];
        this.addCustomerEmailFieldGroup(email);
      }
    } else {
      this.addCustomerEmailFieldGroup();
    }
    if (this.helper.isEmptyObj(this.record)) {
      this.selectCustomer();
    }
    this.customersOpts = this.app.getTypeAheadObservableOptions(this.frmAddEdit, 'customer', 'customers/lookup');
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
    this.evPricingCalculationPolicyChanged(false);
    this.frmAddEdit.controls['schedule_date_from'].valueChanges.subscribe(value => {
      this.ticketDateChange(value, 'schedule_date_from');
    });
    this.frmAddEdit.controls['schedule_date_to'].valueChanges.subscribe(value => {
      this.ticketDateChange(value, 'schedule_date_to');
    });
  }

  addCustomerPhoneFieldGroup(phone: string = '') {
    const formControl = <FormArray>this.frmAddEdit.controls['customer_phone'];
    formControl.push(this.formBuilder.group({
      phone: [phone],
    }));
  }
  removeCustomerPhoneFieldGroup(idx: number) {
    const formControl = <FormArray>this.frmAddEdit.controls['customer_phone'];
    formControl.removeAt(idx);
  }

  addCustomerEmailFieldGroup(email: string = '') {
    const formControl = <FormArray>this.frmAddEdit.controls['customer_email'];
    formControl.push(this.formBuilder.group({
      email: [email],
    }));
  }
  removeCustomerEmailFieldGroup(idx: number) {
    const formControl = <FormArray>this.frmAddEdit.controls['customer_email'];
    formControl.removeAt(idx);
  }

  addCustomerSiteManagerFieldGroup() {
    const id = Date.now() + '_' + (Math.floor(Math.random() * 899) + 100);
    this.customerSiteManagers.push({id: id, name: '', phone: ''});
  }
  removeCustomerSiteManagerFieldGroup(idx: number) {
    this.customerSiteManagers.splice(idx, 1);
  }
  removeSiteManagerFieldGroup(idx: number) {
    this.siteManagers.splice(idx, 1);
  }
  customerSiteManagerExists(siteManager) {
    if (this.customerSiteManagers.findIndex((item) => (item.id === siteManager.id)) >= 0) {
      return true;
    }
    return false;
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  evPricingCalculationPolicyChanged(updateTravelBillingPolicy = true) {
    if (+this.frmAddEdit.controls.pricing_to_be_calculated_on_site.value === 1) {
      this.frmAddEdit.controls['price'].setValidators([]);
      if (updateTravelBillingPolicy) {
        if (
          +this.record.latitude &&
          +this.record.longitude &&
          this.app.settings.base_location &&
          +this.app.settings.base_location.latitude &&
          +this.app.settings.base_location.longitude &&
          +this.helper.haversineDistance([+this.record.latitude, +this.record.longitude], [+this.app.settings.base_location.latitude, +this.app.settings.base_location.longitude], true) >= +this.app.settings.travel_billing_radius
        ) {
          this.frmAddEdit.controls['pricing_to_be_calculated_for_travelling'].setValue(1);
        } else {
          this.frmAddEdit.controls['pricing_to_be_calculated_for_travelling'].setValue(0);
        }
      }
    } else {
      this.frmAddEdit.controls['price'].setValidators([Validators.required]);
      this.frmAddEdit.controls['pricing_to_be_calculated_for_travelling'].setValue(0);
    }
    this.frmAddEdit.controls['price'].updateValueAndValidity();
  }

  ticketDateChange(value, input){
    if(this.frmAddEdit.controls['schedule_date_from'].value === null && this.frmAddEdit.controls['schedule_date_to'].value === null){
    }else if(input === 'schedule_date_from' && (this.frmAddEdit.controls['schedule_date_to'].value === '' ||this.frmAddEdit.controls['schedule_date_to'].value === null)){
      this.frmAddEdit.controls['schedule_date_to'].setValue(value);
    }else if(input === 'schedule_date_to' && (this.frmAddEdit.controls['schedule_date_from'].value === '' || this.frmAddEdit.controls['schedule_date_from'].value === null)){
      this.frmAddEdit.controls['schedule_date_from'].setValue(value);
    }
  }

  save(customerOnly: number = 0) {
    if (!this.frmAddEditSubmitting) {
      this.frmAddEditSubmitting = true;
      if (!customerOnly) {
        this.frmAddEdit.controls['address'].setValidators([Validators.required]);
        this.frmAddEdit.controls['latitude'].setValidators([Validators.required]);
        this.frmAddEdit.controls['longitude'].setValidators([Validators.required]);
        this.frmAddEdit.controls['address'].updateValueAndValidity();
        this.frmAddEdit.controls['latitude'].updateValueAndValidity();
        this.frmAddEdit.controls['longitude'].updateValueAndValidity();
      }
      setTimeout(() => {
        if (this.frmAddEdit.valid) {
          const userInputs: any = this.frmAddEdit.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.record_id = this.recordID;
          reqParams.customer_id = userInputs.customer_id;
          reqParams.customer_name = userInputs.customer_name;
          reqParams.customer_address = userInputs.customer_address;
          reqParams.customer_latitude = userInputs.customer_latitude;
          reqParams.customer_longitude = userInputs.customer_longitude;
          reqParams.customer_ste = userInputs.customer_ste;
          reqParams.customer_door_code = userInputs.customer_door_code;
          reqParams.customer_phone = userInputs.customer_phone;
          reqParams.customer_email = userInputs.customer_email;
          reqParams.customer_site_managers = JSON.stringify(this.customerSiteManagers.filter((item) => (item.name.trim() !== '' || item.phone.trim() !== '')));
          reqParams.customer_only = customerOnly;
          reqParams.site_managers = this.customerSiteManagers.filter((item) => (item.id === userInputs.site_managers));
          reqParams.site_managers = (reqParams.site_managers.length === 0) ? this.siteManagers.filter((item) => (item.id === userInputs.site_managers)) : reqParams.site_managers;
          reqParams.site_managers = JSON.stringify(reqParams.site_managers);
          reqParams.address = userInputs.address;
          reqParams.latitude = userInputs.latitude;
          reqParams.longitude = userInputs.longitude;
          reqParams.ste = userInputs.ste;
          reqParams.door_code = userInputs.door_code;
          reqParams.duration_hours = userInputs.duration_hours;
          reqParams.duration_days = userInputs.duration_days;
          reqParams.square_footage = userInputs.square_footage;
          reqParams.price = userInputs.price;
          reqParams.pricing_to_be_calculated_on_site = (userInputs.pricing_to_be_calculated_on_site) ? 1 : 0;
          reqParams.pricing_to_be_calculated_for_travelling = (userInputs.pricing_to_be_calculated_for_travelling) ? 1 : 0;
          reqParams.billing_status = userInputs.billing_status;
          reqParams.billing_method = userInputs.billing_method;
          reqParams.billing_card = userInputs.billing_card;
          reqParams.billing_card_number = userInputs.billing_card_number;
          reqParams.billing_card_expiry = userInputs.billing_card_expiry;
          reqParams.billing_card_cvv = userInputs.billing_card_cvv;
          reqParams.schedule_date_from = this.helper.dFormatToDB(userInputs.schedule_date_from);
          reqParams.schedule_date_to = this.helper.dFormatToDB(userInputs.schedule_date_to);
          reqParams.schedule_time = this.helper.tFormatToDBFrom24HoursFormat(userInputs.schedule_time);
          reqParams.send_contract_to_customer = (userInputs.send_contract_to_customer) ? 1 : 0;
          reqParams.note = userInputs.note;
          reqParams.services = this.services.filter((item) => (item.isSelected)).map((item) => item.id).join(',');
          reqParams.supplies = JSON.stringify(this.supplies);
          reqParams.crews = this.crews.filter((item) => (item.isSelected)).map((item) => item.id).join(',');
          if (userInputs.media_files && userInputs.media_files.length > 0) {
            reqParams.media_files = userInputs.media_files;
          }
          reqParams.remove_media_files = (this.record && this.record.media_files) ? this.record.media_files.filter((item) => (item.doRemove)).map((item) => item.filename) : [];

          this.helper.makeAPIRequest('tickets/save', reqParams).then((response) => {
            if (response.success === 1) {
              if (reqParams.customer_only) {
                this.frmAddEditSubmitting = false;
                this.helper.showNotification('success', 'Customer info saved!', this.helper.config.defaultSuccessTitle);
                this.frmAddEdit.controls['customer_id'].setValue(this.helper.getObjVal(response, ['data', 'customer_id'], 0));
              } else {
                this.helper.showNotification('success', 'Ticket info saved!', this.helper.config.defaultSuccessTitle);
                this.app.reloadTickets.emit('ticket_saved');
                this.helper.navBack();
              }
            } else if (response.error === 1) {
              this.frmAddEditSubmitting = false;
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.frmAddEditSubmitting = false;
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmAddEditSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        } else {
          this.frmAddEditSubmitting = false;
        }
        this.frmAddEdit.controls['address'].setValidators([]);
        this.frmAddEdit.controls['latitude'].setValidators([]);
        this.frmAddEdit.controls['longitude'].setValidators([]);
        this.frmAddEdit.controls['address'].updateValueAndValidity();
        this.frmAddEdit.controls['latitude'].updateValueAndValidity();
        this.frmAddEdit.controls['longitude'].updateValueAndValidity();
      }, 0);
    }
  }

}

