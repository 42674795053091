import { Component, ViewChild, OnInit, OnDestroy, ElementRef, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import dayGridPlugin from '@fullcalendar/daygrid';

import { IdeateHelper } from '../core/providers/ideate';
import { Account } from '../providers/account';
import { App } from '../providers/app';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('datatable') dtElementRef: ElementRef;

  public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public dtColsCount = 0;
  public dtRows: any[];
  public selectedRecordID = 0;

  @ViewChild('sidebarCalendar') public sidebarCalendar: FullCalendarComponent;
  public sidebarCalendarPlugins = [dayGridPlugin];
  public sidebarCalendarAPI;

  public filterKeyword = '';
  public filterBillingStatuses: any = [];
  public filterTicketStatuses: any = [];
  public filterOrderBy = 'latest';
  public filterOrderByOptions = [{label: 'Latest', value: 'latest'}, {label: 'Largest', value: 'largest'}, {label: 'Smallest', value: 'smallest'}];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public app: App,
    public helper: IdeateHelper,
    private account: Account
  ) {
      this.filterBillingStatuses = this.app.billingStatuses.map(item => { item.checked = true; return item;});
      this.filterTicketStatuses = this.app.ticketStatuses.map(item => { item.checked = (item.value=='completed')?true:false; return item;});
  }

  ngOnInit() {
    this.dtColsCount = this.dtElementRef.nativeElement.children[0].children[0].children.length;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.initDataTable();
      setTimeout(() => {
        this.sidebarCalendarAPI = this.sidebarCalendar.getApi();
      }, 100);
    });
  }

  evBillingFilterUpdated() {
    this.initDataTable();
  }

  initDataTable() {
    setTimeout(() => {
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.loadDatable();
        });
      } else {
        this.loadDatable();
      }
    }, 0);
  }

  loadDatable() {
    const that = this;
    const reqParams: any = {};
    reqParams.user_id = this.account.info.id;
    reqParams.auth_token = this.account.info.auth_token;
    reqParams.filter_keyword = this.filterKeyword;
    reqParams.filter_order_by = this.filterOrderBy;
    reqParams.filter_billing_status = this.filterBillingStatuses.filter(item=>item.checked).map(item=>item.value);
    reqParams.filter_ticket_status = this.filterTicketStatuses.filter(item=>item.checked).map(item=>item.value);
    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthMenu: [[10, 50, 100, 200, 500, 1000], [10, 50, 100, 200, 500, 1000]],
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: true,
      dom: 'l"("i")"rtp',
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters = Object.assign(dataTablesParameters, reqParams);
        this.helper.makeAPIRequest('tickets/list_billing', dataTablesParameters).then((response) => {
          if (response.success === 1) {
            that.dtRows = response.data.datatable.rows;
            callback({
              recordsTotal: response.data.datatable.recordsTotal,
              recordsFiltered: response.data.datatable.recordsFiltered,
              data: []
            });
          } else if (response.error === 1) {
            if (response.errorCode === 2) {
              this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
              this.account.logOut();
            } else {
              this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          } else {
            this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        }).catch((httpError) => {
          this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
        });
      }
    };
    setTimeout(() => { this.dtTrigger.next(); }, 0);
  }

  ngOnDestroy(): void {
  }
}
