import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewEncapsulation, SimpleChanges, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { IdeateHelper } from '../../providers/ideate';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaGalleryComponent implements OnInit, OnChanges {

  @Input() mediaFiles: any[]; // { name: string, url: string, type: string}
  @Input() activeMediaFileName;
  public mediaGalleryItems: any[];
  public activeMediaItemIndex: number;
  // public isFirstMediaActive: Boolean;
  // public isLastMediaActive: Boolean;

  @HostListener('document:keydown', ['$event']) onMouseEnter($event: KeyboardEvent) {
    if (this.router.url.indexOf('page:tickets/info/') !== -1) {
      if ($event.which === 37 && this.activeMediaItemIndex > 0) {
        this.goToMedia(this.activeMediaItemIndex - 1);
      } else if ($event.which === 39 && (this.activeMediaItemIndex < (this.mediaGalleryItems.length - 1))) {
        this.goToMedia(this.activeMediaItemIndex + 1);
      }
    }
  }

  constructor(private router: Router, public helper: IdeateHelper) { }

  ngOnInit() {
    this.initGallery();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('mediaFiles')) {
      this.initGallery();
    }
    setTimeout(() => {
      if (changes.hasOwnProperty('activeMediaFileName')) {
        if (Array.isArray(this.mediaGalleryItems) && this.mediaGalleryItems.length > 0) {
          const activeIndex = this.helper.getArrayIndex(this.mediaGalleryItems, 'fileName', this.activeMediaFileName);
          if (activeIndex !== false) {
            this.goToMedia(activeIndex);
          }
        }
      }
    }, 100);
  }

  initGallery() {
    this.activeMediaFileName = '';
    this.mediaGalleryItems = [];
    this.activeMediaItemIndex = -1;
    // this.isFirstMediaActive = true;
    // this.isLastMediaActive = true;
    if (Array.isArray(this.mediaFiles)) {
      this.mediaFiles.forEach(mediaFile => {
        const mediaGalleryItem = {
          fileName: mediaFile.name,
          url: mediaFile.url,
          type: mediaFile.type,
          caption: mediaFile.caption,
          isActive: false
        };
        this.mediaGalleryItems.push(mediaGalleryItem);
      });
      this.goToMedia(0);
    }
  }

  goToMedia(activeIndex: number) {
    if (Array.isArray(this.mediaGalleryItems) && this.mediaGalleryItems.length > 0) {
      this.mediaGalleryItems.map(item => { item.isActive = false; return item; });
      if (activeIndex >= 0 && this.mediaGalleryItems[activeIndex]) {
        this.mediaGalleryItems[activeIndex].isActive = true;
      }
    }
    const activeMediaItemIndex = this.helper.getArrayIndex(this.mediaGalleryItems, 'isActive', true);
    this.activeMediaItemIndex = (activeMediaItemIndex !== false) ? activeMediaItemIndex : this.activeMediaItemIndex;
    // this.isFirstMediaActive = (activatedIndex === false || activatedIndex <= 0) ? true : false;
    // this.isLastMediaActive = (activatedIndex === false || activatedIndex >= this.mediaGalleryItems.length - 1) ? true : false;
  }

}
