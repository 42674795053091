import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';

import 'rxjs/add/operator/switchMap';
import { GoogleMapSearchComponent } from '../../core/components/google-map-search/google-map-search.component';
import { App } from '../../providers/app';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-customers',
  templateUrl: './add-edit-customers.component.html',
  styleUrls: ['./add-edit-customers.component.scss']
})
export class AddEditCustomersComponent implements OnInit, AfterViewInit {

  @ViewChild(GoogleMapSearchComponent) googleMapSearchComponent: GoogleMapSearchComponent;
  public userType = 'admin';
  public frmAddEdit: FormGroup;
  public frmAddEditSubmitting: Boolean = false;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    name: {
      required: 'Name is required.'
    },
    address: {},
    ste: {},
    door_code: {},
    site_manager: {}
  };

  public siteManagers: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public helper: IdeateHelper,
    public app: App,
    private customValidators: IdeateValidators,
    private account: Account
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userType = params.get('type') ? params.get('type') : this.userType;
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.getRecordData();
    });

    this.initForm();
  }

  ngAfterViewInit() {
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('customers/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          this.record.latitude = +this.record.latitude;
          this.record.longitude = +this.record.longitude;
          this.siteManagers = this.record.site_managers;
          if (this.siteManagers.length && this.siteManagers.length > 0) {
            for (let i = 0; i < this.siteManagers.length; i++) {
              this.siteManagers[i].name = this.siteManagers[i].name.replaceAll(/&#039;/g, "'");
            }
          }
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 4) {
            this.helper.showNotification('danger', this.helper.config.defaultNoResultErrorMsg, this.helper.config.defaultErrorTitle);
            this.helper.navBack();
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      name: [this.record.name, Validators.compose([Validators.required])],
      phone: this.formBuilder.array([]),
      email: this.formBuilder.array([]),
      address: [this.record.address],
      latitude: [this.record.latitude, Validators.compose([Validators.required])],
      longitude: [this.record.longitude, Validators.compose([Validators.required])],
      ste: [this.record.ste],
      door_code: [this.record.door_code],
    });
    if (this.record.phone && this.record.phone.length > 0) {
      for (let i = 0; i < this.record.phone.length; i++) {
        const phone = this.record.phone[i];
        this.addPhoneFieldGroup(phone);
      }
    } else {
      this.addPhoneFieldGroup();
    }
    if (this.record.email && this.record.email.length > 0) {
      for (let i = 0; i < this.record.email.length; i++) {
        const email = this.record.email[i];
        this.addEmailFieldGroup(email);
      }
    } else {
      this.addEmailFieldGroup();
    }
    if (this.siteManagers.length <= 0) {
      this.addSiteManagerFieldGroup();
    }
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  addPhoneFieldGroup(phone: string = '') {
    const formControl = <FormArray>this.frmAddEdit.controls['phone'];
    formControl.push(this.formBuilder.group({
      phone: [phone],
    }));
  }
  removePhoneFieldGroup(idx: number) {
    const formControl = <FormArray>this.frmAddEdit.controls['phone'];
    formControl.removeAt(idx);
  }

  addEmailFieldGroup(email: string = '') {
    const formControl = <FormArray>this.frmAddEdit.controls['email'];
    formControl.push(this.formBuilder.group({
      email: [email],
    }));
  }
  removeEmailFieldGroup(idx: number) {
    const formControl = <FormArray>this.frmAddEdit.controls['email'];
    formControl.removeAt(idx);
  }

  addSiteManagerFieldGroup() {
    const id = Date.now() + '_' + (Math.floor(Math.random() * 899) + 100);
    this.siteManagers.push({id: id, name: '', phone: ''});
  }
  removeSiteManagerFieldGroup(idx: number) {
    this.siteManagers.splice(idx, 1);
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  save() {
    this.googleMapSearchComponent.validateForm();
    this.validateForm();
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        if (!this.frmAddEditSubmitting) {
          this.frmAddEditSubmitting = true;
          const userInputs: any = this.frmAddEdit.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.record_id = this.recordID;
          reqParams.name = userInputs.name;
          reqParams.email = userInputs.email;
          reqParams.phone = userInputs.phone;
          reqParams.address = userInputs.address;
          reqParams.latitude = userInputs.latitude;
          reqParams.longitude = userInputs.longitude;
          reqParams.ste = userInputs.ste;
          reqParams.door_code = userInputs.door_code;
          reqParams.site_managers = JSON.stringify(this.siteManagers.filter((item) => (item.name.trim() !== '' || item.phone.trim() !== '')));
          this.helper.makeAPIRequest('customers/save', reqParams).then((response) => {
            this.frmAddEditSubmitting = false;
            if (response.success === 1) {
              this.helper.showNotification('success', 'Customer info saved!', this.helper.config.defaultSuccessTitle);
              this.helper.navBack();
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmAddEditSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }

  onAddressChanged(address: string) {
    this.frmAddEdit.controls['address'].setValue(address);
  }

  onLatLongChanged(latLong: any) {
    this.frmAddEdit.controls['latitude'].setValue(latLong.latitude);
    this.frmAddEdit.controls['longitude'].setValue(latLong.longitude);
  }
}

