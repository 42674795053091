import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { IdeateHelper, IdeateValidators } from '../../core/providers/ideate';
import { Account } from '../../providers/account';
import { App } from '../../providers/app';

import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-service',
  templateUrl: './add-edit-service.component.html',
  styleUrls: ['./add-edit-service.component.scss']
})
export class AddEditServiceComponent implements OnInit {

  public pricingAreaDefaultTier: any;
  public pricingArea: any;
  /* public pricingItemDefault: any;
  public pricingItem: any[]; */
  public suppliesSelected: any[];
  public suppliesOpts: Observable<any>;

  public frmAddEdit: FormGroup;
  public frmAddEditSubmitting: Boolean = false;
  public recordID = 0;
  public record: any = {};
  public validationMessages: any = {
    provider: {
      required: 'Provider is required.'
    },
    title: {
      required: 'Title is required.',
      remote: 'Service already exists.'
    },
    icon: {
      allowedType: 'Invalid file type. Please select jpg/jpeg/png/gif/svg file only.'
    },
    pricing_mode: {
      required: 'Pricing mode is required.'
    },
    price_hourly: {
    },
    price_fixed: {
    },
    price_area: {
    },
    /* price_item: {
    }, */
    has_junk: {
    },
    requires_supplies: {
    },
    supply: {
    },
    contract_file: {
      allowedType: 'Invalid file type. Please select pdf file only.'
    }
  };

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public app: App,
    public helper: IdeateHelper,
    private customValidators: IdeateValidators,
    private account: Account,
  ) { }

  ngOnInit() {
    this.pricingAreaDefaultTier = {min_area: 0, max_area: 0, price: 1};
    this.pricingArea = {price: 0, has_tiered_pricing: false, tiered_pricing: [JSON.parse(JSON.stringify(this.pricingAreaDefaultTier))]};
    /* this.pricingItemDefault = {name: '', price: 0, has_tiered_pricing: false, tiered_pricing: [{min_area: 0, max_area: 0, price: 1}]};
    this.pricingItem = [JSON.parse(JSON.stringify(this.pricingItemDefault))]; */
    this.suppliesSelected = [];

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.recordID = (params.get('id')) ? +params.get('id') : this.recordID;
      this.getRecordData();
    });

    this.initForm();
  }

  getRecordData() {
    if (this.recordID === 0) {
      this.initForm();
    } else {
      const reqParams: any = {};
      reqParams.user_id = this.account.info.id;
      reqParams.auth_token = this.account.info.auth_token;
      reqParams.record_id = this.recordID;
      this.helper.makeAPIRequest('services/get', reqParams).then((response) => {
        if (response.success === 1) {
          this.record = response.data.record;
          if (!this.helper.isEmptyObj(this.record.price_area)) {
            this.pricingArea = this.record.price_area;
          }
          /* if (!this.helper.isEmptyObj(this.record.price_item)) {
            this.pricingItem = this.record.price_item;
          } */
          this.suppliesSelected = this.record.supplies;
          this.initForm();
        } else if (response.error === 1) {
          if (response.errorCode === 2) {
            this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
            this.account.logOut();
          } else if (response.errorCode === 5) {
            this.helper.showNotification('danger', 'Oops!', 'Service already exists.');
          } else {
            this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
          }
        } else {
          this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
        }
      }).catch((httpError) => {
        this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
      });
    }
  }

  initForm() {
    this.frmAddEdit = this.formBuilder.group({
      provider: [(this.record.provider) ? this.record.provider : '', Validators.compose([Validators.required])],
      title: [this.record.title, Validators.compose([Validators.required]), this.customValidators.remote('services/check_duplicate', 'title', { record_id: this.recordID })],
      icon: ['', Validators.compose([this.customValidators.allowedType(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'])])],
      pricing_mode: [this.record.pricing_mode, Validators.compose([Validators.required])],
      price_hourly: [((this.record.price_hourly) ? this.record.price_hourly : '0')],
      price_fixed: [((this.record.price_fixed) ? this.record.price_fixed : '0')],
      has_junk: [+this.record.has_junk],
      requires_supplies: [+this.record.requires_supplies],
      supply: [],
      contract_file: ['', Validators.compose([this.customValidators.allowedType(['application/pdf'])])]
    });
    this.suppliesOpts = this.app.getTypeAheadObservableOptions(this.frmAddEdit, 'supply', 'supplies/lookup');
    this.frmAddEdit.valueChanges.subscribe((data) => {
      this.validateForm();
    });
    this.validateForm();
  }

  validateForm() {
    this.validationMessages = this.customValidators.getValidationErrors(this.frmAddEdit, this.validationMessages);
  }

  evOptsSupplySelected(event: any): void {
    this.addSupply(event.item);
    this.frmAddEdit.controls['supply'].setValue('');
  }
  evOptsSupplyChanged(event: any): void {
    setTimeout(() => {
      this.frmAddEdit.controls['supply'].setValue('');
    }, 100);
  }
  addSupply(supply: any) {
    if (this.suppliesSelected.filter((item) => (item.value === supply.value)).length === 0) {
      this.suppliesSelected.push(supply);
    }
  }
  removeSupply(index) {
    if (this.suppliesSelected[index]) {
      this.suppliesSelected.splice(index, 1);
    }
  }

  addPricingAreaTier(idxTier: number) {
    this.pricingArea.tiered_pricing.splice((idxTier + 1), 0, JSON.parse(JSON.stringify(this.pricingAreaDefaultTier)));
  }
  removePricingAreaTier(idxTier: number) {
    this.pricingArea.tiered_pricing.splice(idxTier, 1);
  }

  /* addPricingItem(idxItem: number) {
    this.pricingItem.splice((idxItem + 1), 0, JSON.parse(JSON.stringify(this.pricingItemDefault)));
  }
  removePricingItem(idxItem: number) {
    this.pricingItem.splice(idxItem, 1);
  }

  addPricingItemTier(idxItem: number, idxTier: number) {
    this.pricingItem[idxItem].tiered_pricing.splice((idxTier + 1), 0, JSON.parse(JSON.stringify(this.pricingItemDefault.tiered_pricing[0])));
  }
  removePricingItemTier(idxItem: number, idxTier: number) {
    this.pricingItem[idxItem].tiered_pricing.splice(idxTier, 1);
  } */

  save() {
    setTimeout(() => {
      if (this.frmAddEdit.valid) {
        if (!this.frmAddEditSubmitting) {
          this.frmAddEditSubmitting = true;
          const userInputs: any = this.frmAddEdit.value;
          const reqParams: any = {};
          reqParams.user_id = this.account.info.id;
          reqParams.auth_token = this.account.info.auth_token;
          reqParams.record_id = this.recordID;
          reqParams.provider = userInputs.provider;
          reqParams.title = userInputs.title;
          reqParams.pricing_mode = userInputs.pricing_mode;
          reqParams.price_hourly = userInputs.price_hourly;
          reqParams.price_fixed = userInputs.price_fixed;
          reqParams.price_area = JSON.stringify(this.pricingArea);
          /* reqParams.price_item = JSON.stringify(this.pricingItem); */
          reqParams.has_junk = (userInputs.has_junk) ? 1 : 0;
          reqParams.requires_supplies = (userInputs.requires_supplies) ? 1 : 0;
          reqParams.supplies = this.suppliesSelected.map(item => item.value).join(',');
          if (userInputs.icon && userInputs.icon.length > 0) {
            reqParams.icon = userInputs.icon[0];
          }
          if (userInputs.contract_file && userInputs.contract_file.length > 0) {
            reqParams.contract_file = userInputs.contract_file[0];
          }

          this.helper.makeAPIRequest('services/save', reqParams).then((response) => {
            this.frmAddEditSubmitting = false;
            if (response.success === 1) {
              this.helper.showNotification('success', 'Service info saved!', this.helper.config.defaultSuccessTitle);
              this.helper.navBack();
            } else if (response.error === 1) {
              if (response.errorCode === 2) {
                this.helper.showNotification('danger', this.helper.config.defaultAuthErrorMsg, this.helper.config.defaultAuthErrorTitle);
                this.account.logOut();
              } else {
                this.helper.showNotification('danger', 'API_ERROR ' + response.errorCode + ' : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
              }
            } else {
              this.helper.showNotification('danger', 'API_ERROR : ' + this.helper.config.defaultErrorMsg, this.helper.config.defaultErrorTitle);
            }
          }).catch((httpError) => {
            this.frmAddEditSubmitting = false;
            this.helper.showNotification('danger', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage, this.helper.config.defaultErrorTitle);
          });
        }
      }
    }, 0);
  }
}

