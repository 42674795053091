import { Pipe, PipeTransform } from '@angular/core';
import { IdeateHelper } from '../core/providers/ideate';

@Pipe({
  name: 'serviceNames'
})
export class ServiceNamesPipe implements PipeTransform {

  constructor(private helper: IdeateHelper) { }

  transform(value: any, args?: any): any {
    const servicesSelected = [];
    if (value && value.length > 0) {
      for (const item of value) {
        const serviceSelectedIdx = this.helper.getArrayIndex(servicesSelected, 'id', item.id);
        if (serviceSelectedIdx === false) {
          const serviceSelected = {id: item.id, title: item.title, qty: 1};
          servicesSelected.push(serviceSelected);
        } else {
          servicesSelected[serviceSelectedIdx].qty++;
        }
      }
    }

    const serviceNames = [];
    if (servicesSelected.length > 0) {
      for (const service of servicesSelected) {
        let serviceName = service.title;
        if (service.qty > 1) {
          serviceName += ' (' + service.qty + ')';
        }
        serviceNames.push(serviceName);
      }
    }
    return (serviceNames && serviceNames.length > 0) ? serviceNames.join(', ') : '';
  }

}
