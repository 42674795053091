import { Injectable, EventEmitter } from '@angular/core';

import { IdeateHelper } from '../core/providers/ideate';
import { Account } from './account';
import { Observable, Subject } from 'rxjs';
import { CommonVM } from '../core/models/common-vm';
import { TicketStatusVM } from '../core/models/ticket-status-vm';

@Injectable()
export class App {
	public userRoles: CommonVM[] = [];
	public providers: CommonVM[] = [];
	public billingStatuses: CommonVM[] | any = [];
	public billingMethods: CommonVM[] | any = [];
	public ticketStatuses: TicketStatusVM[] | any = [];
	public settings: any;
	public reloadTickets = new EventEmitter<string>();

	constructor(
		public ideateHelper: IdeateHelper,
		public account: Account
	) {

		this.userRoles = [
			{ label: 'Admin', value: 'admin' },
			{ label: 'Crew', value: 'crew' },
			{ label: 'Crew Member', value: 'crew_member' },
		];

		this.providers = [
			{ label: 'EverKleen', value: 'everkleen' },
			{ label: 'Wynn\'s bins', value: 'wynnsbins' }
		];

		this.billingStatuses = [
			{ label: 'Pending Invoice', value: 'pending_invoice' },
			{ label: 'Pending Payment', value: 'pending_payment' },
			{ label: 'Payment received', value: 'payment_received' },
		];

		this.billingMethods = [
			{ label: 'Cash', value: 'cash' },
			{ label: 'Check', value: 'check' },
			{ label: 'Card', value: 'card' },
		];

		this.ticketStatuses = [
			{ label: 'Open', value: 'open', color_030: 'rgba(235, 64, 64, 30%)', color_100: 'rgba(235, 64, 64, 100%)' },
			{ label: 'Assigned', value: 'assigned', color_030: 'rgba(165, 111, 234, 30%)', color_100: 'rgba(165, 111, 234, 100%)' },
			{ label: 'OTW', value: 'otw', color_030: 'rgba(231, 127, 68, 30%)', color_100: 'rgba(231, 127, 68, 100%)' },
			{ label: 'Arrived', value: 'arrived', color_030: 'rgba(231, 68, 166, 30%)', color_100: 'rgba(231, 68, 166, 100%)' },
			{ label: 'Started', value: 'started', color_030: 'rgba(238, 184, 46, 30%)', color_100: 'rgba(238, 184, 46, 100%)' },
			{ label: 'Paused', value: 'paused', color_030: 'rgba(163, 163, 163, 30%)', color_100: 'rgba(163, 163, 163, 100%)' },
			{ label: 'Completed', value: 'completed', color_030: 'rgba(13, 217, 143, 30%)', color_100: 'rgba(13, 217, 143, 100%)' },
			{ label: 'Paid', value: 'paid', color_030: 'rgba(35, 193, 32, 30%)', color_100: 'rgba(35, 193, 32, 100%)' }
		];

		const settings = localStorage.getItem('siteSettings');
		this.settings = (this.ideateHelper.isValidJSON(settings)) ? JSON.parse(settings) : {};
	}

	public getTicketStatus_color_030(status) {
		const ticketStatus = this.ticketStatuses.filter((item) => (item.value === status));
		if (ticketStatus && ticketStatus.length === 1) {
			return ticketStatus[0].color_030;
		} else {
			return "rgba(0, 0, 0, 30%)";
		}
	}

	public getTicketStatus_color_100(status) {
		const ticketStatus = this.ticketStatuses.filter((item) => (item.value === status));
		if (ticketStatus && ticketStatus.length === 1) {
			return ticketStatus[0].color_100;
		} else {
			return "rgba(0, 0, 0, 100%)";
		}
	}

	public getTypeAheadObservableOptions(form: any, userInput: string, apiEndPoint: string, params: any = {}): Observable<any> {
		return new Observable((observer: any) => {
			const userInputs: any = form.value;
			observer.next(userInputs[userInput]);
		}).mergeMap((keyword: string) => {
			let reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.keyword = keyword;
			reqParams = { ...reqParams, ...params };
			return this.ideateHelper.makeAPIRequest(apiEndPoint, reqParams).then((response) => {
				let items: any[] = [];
				if (response.success === 1) {
					items = response.data.records;
				}
				return items;
			}).catch((httpError) => {
				return [];
			});
		});
	}

	getNGMTypeAheadObservableOptions(ngModel: any, apiEndPoint: string, params: any = {}): Observable<any> {
		return new Observable((observer: any) => {
			observer.next(ngModel);
		}).mergeMap((keyword: string) => {
			let reqParams: any = {};
			reqParams.user_id = this.account.info.id;
			reqParams.auth_token = this.account.info.auth_token;
			reqParams.keyword = keyword;
			reqParams = { ...reqParams, ...params };
			return this.ideateHelper.makeAPIRequest(apiEndPoint, reqParams).then((response) => {
				let items: any[] = [];
				if (response.success === 1) {
					items = response.data.records;
				}
				return items;
			}).catch((httpError) => {
				return [];
			});
		});
	}
}